import Swiper from 'swiper/bundle';
import { map, lerp, clamp, getMousePos } from './menu_hover_image/utils';


const $cursor = $('.cursor');
const w_cursor = 60;
const h_cursor = 60;
const w_cursor_big = 130;
const h_cursor_big = 130;
const bg_cursor = 'transparent';
const border_cursor = '#787878';

var lastScrolledLeft = 0;
var lastScrolledTop = 0;

// Track the mouse position
let mouse = {x: 0, y: 0};
window.addEventListener("mousemove", ev => (mouse = getMousePos(ev)));
window.addEventListener("mousedown", ev => (mouse = getMousePos(ev)));

export default class Cursor {
    constructor(el) {
        var self = this;
        this.DOM = {el: el};
        this.DOM.el.style.opacity = 0;

        this.isStuck = false;
        this.isHover = false;
        this.block = false;

        this.bounds = w_cursor;

        this.renderedStyles = {
            tx: {previous: 0, current: 0, amt: 0.2},
            ty: {previous: 0, current: 0, amt: 0.2}
        };

        this.onMouseMoveEv = () => {

            this.renderedStyles.tx.previous = this.renderedStyles.tx.current = mouse.x - this.bounds / 2;
            this.renderedStyles.ty.previous = this.renderedStyles.ty.previous = mouse.y - this.bounds / 2;

            gsap.to(this.DOM.el, {duration: 0.1, ease: "Power3.easeOut", opacity: 1});

            requestAnimationFrame(() => this.render());
            window.removeEventListener("mousemove", this.onMouseMoveEv);
            window.removeEventListener("mousedown", this.onMouseMoveEv);
        };

        window.addEventListener("mousemove", this.onMouseMoveEv);
        window.addEventListener("mousedown", this.onMouseMoveEv);


        //aggiunto per aggiornarlo allo scroll
        var timer = null;
        $(window).scroll(function () {
            if (lastScrolledLeft != $(document).scrollLeft()) {
                mouse.x -= lastScrolledLeft;
                lastScrolledLeft = $(document).scrollLeft();
                mouse.x += lastScrolledLeft;
            }
            if (lastScrolledTop != $(document).scrollTop()) {
                mouse.y -= lastScrolledTop;
                lastScrolledTop = $(document).scrollTop();
                mouse.y += lastScrolledTop;
            }

            //scroll si allunga
//            gsap.to($cursor, {height: 100, 'border-radius': '30px', onComplete: function () {
//                    
//                }});
//
//            if (timer !== null) {
//                clearTimeout(timer);
//            }
//            timer = setTimeout(function () {
//                gsap.to($cursor, {height: h_cursor, 'border-radius': '100%', onComplete: function () {
//                    
//                }});
//            }, 150);
        });


        //// CAMBIA IL CURSORE IN BASE ALL'ELEMENTO SU CUI SI TROVA ////

        const link_project = document.querySelectorAll(".link-project");
        link_project.forEach(item => {
            item.addEventListener("mouseenter", function () {
                self.bounds = w_cursor_big;
                //self.isHover = true;
                gsap.to($cursor, {width: 130, height: 130, 'border-color': '#E30613', 'background-color': 'rgba(31, 31, 31, 0.20)', onComplete: function () {
                        $cursor.html('Scopri il progetto');
                    }});
            });
            item.addEventListener("mouseleave", function () {
                self.bounds = w_cursor;
                //self.isHover = false;
                $cursor.html('');
                gsap.to($cursor, {width: w_cursor, height: h_cursor, 'border-color': border_cursor, 'background-color': bg_cursor, onUpdate: function () {
                        $cursor.html('');
                    }});
            });
        });


        const view_gallery = document.querySelectorAll(".view-gallery");
        view_gallery.forEach(item => {
            item.addEventListener("mouseenter", function () {
                self.bounds = w_cursor_big;
                //self.isHover = true;
                gsap.to($cursor, {width: 130, height: 130, 'border-color': '#E30613', 'background-color': 'rgba(31, 31, 31, 0.20)', onComplete: function () {
                        $cursor.html('Vedi la galleria');
                    }});
            });
            item.addEventListener("mouseleave", function () {
                self.bounds = w_cursor;
                //self.isHover = false;
                $cursor.html('');
                gsap.to($cursor, {width: w_cursor, height: h_cursor, 'border-color': border_cursor, 'background-color': bg_cursor, onUpdate: function () {
                        $cursor.html('');
                    }});
            });
        });


        const video_controls = document.querySelectorAll(".video-controls");
        video_controls.forEach(item => {
            item.addEventListener("mouseenter", function () {
                self.bounds = w_cursor_big;
                var text = '';
                if (item.paused) {
                    text = 'Play Video';
                } else {
                    text = 'Pause Video';
                }
                gsap.to($cursor, {width: 130, height: 130, 'border-color': '#E30613', 'background-color': 'rgba(31, 31, 31, 0.20)', onComplete: function () {
                        $cursor.html(text);
                    }});
            });
            item.addEventListener("mouseleave", function () {
                self.bounds = w_cursor;
                $cursor.html('');
                gsap.to($cursor, {width: w_cursor, height: h_cursor, 'border-color': border_cursor, 'background-color': bg_cursor, onUpdate: function () {
                        $cursor.html('');
                    }});
            });
        });


        const point_light = document.querySelectorAll(".ct-point-light");
        point_light.forEach(item => {
            item.addEventListener("mouseenter", function () {
                self.isStuck = true;
                self.w_item = $(item).width();
                self.h_item = $(item).height();
                self.x_item = $(item).offset().left;
                self.y_item = $(item).offset().top + 10;

                //gsap.to($cursor, {scale: 1, 'border-color': '#E30613', 'background-color': 'rgba(31, 31, 31, 0.20)'});
                gsap.to($cursor, {width: 130, height: 130, 'border-color': '#E30613', 'background-color': 'rgba(31, 31, 31, 0.20)', onComplete: function () {
                        self.block = true;
                    }});
            });
            item.addEventListener("mouseleave", function () {
                self.isStuck = false;

                //gsap.to($cursor, {scale: .45, 'border-color': border_cursor, 'background-color': bg_cursor});
                gsap.to($cursor, {duration: .1, width: w_cursor, height: h_cursor, 'border-color': border_cursor, 'background-color': bg_cursor, onUpdate: function () {
                        self.block = false;
                        $cursor.hide();
                        setTimeout(function () {
                            $cursor.show();
                        }, 150);
                    }});
            });
        });


        const point_gallery = document.querySelectorAll(".ct-point-gallery");
        point_gallery.forEach(item => {
            item.addEventListener("mouseenter", function () {
                self.isStuck = true;
                self.w_item = $(item).width();
                self.h_item = $(item).height();
                self.x_item = $(item).offset().left;
                self.y_item = $(item).offset().top;

                gsap.to($cursor, {width: 20, height: 20,
                    //  'border-color': '#fff', 'background-color': '#fff', 'background-image': 'url(assets/img/icons/plus.svg)', 'background-size': '20px',
                    onComplete: function () {
                        // $cursor.html('<span class="text-inner">Guarda la gallery</span>');
                        self.block = true;
                    }});
            });
            item.addEventListener("mouseleave", function () {
                self.isStuck = false;

                gsap.to($cursor, {duration: .1, width: w_cursor, height: h_cursor, 'border-color': border_cursor, 'background-color': bg_cursor, 'background-image': 'none', onUpdate: function () {
                        $cursor.html('');
                        self.block = false;
                        $cursor.hide();
                        setTimeout(function () {
                            $cursor.show();
                        }, 150);
                    },
//                    onComplete: function () {
//                        var interval = setInterval(function () {
//                            console.log('complete')
//                            if (self.block) {
//                                clearInterval(interval);
//                                $cursor.html('');
//                                $cursor.hide();
//                                $cursor.show();
//                                self.block = false;
//                                console.log('complete2')
//                            }
//                        });
//                    }
                });
            });
        });


        const point_video = document.querySelectorAll(".ct-point-video");
        point_video.forEach(item => {
            item.addEventListener("mouseenter", function () {
                self.isStuck = true;
                self.w_item = $(item).width();
                self.h_item = $(item).height();
                self.x_item = $(item).offset().left;
                self.y_item = $(item).offset().top;

                gsap.to($cursor, {width: 20, height: 20,
                    //  'border-color': '#fff', 'background-color': '#fff', 'background-image': 'url(assets/img/icons/plus.svg)', 'background-size': '20px',
                    onComplete: function () {
                        // $cursor.html('<span class="text-inner">Guarda la gallery</span>');
                        self.block = true;
                    }});
            });
            item.addEventListener("mouseleave", function () {
                self.isStuck = false;

                gsap.to($cursor, {duration: .1, width: w_cursor, height: h_cursor, 'border-color': border_cursor, 'background-color': bg_cursor, 'background-image': 'none', onUpdate: function () {
                        $cursor.html('');
                        self.block = false;
                        $cursor.hide();
                        setTimeout(function () {
                            $cursor.show();
                        }, 150);
                    }});
            });
        });


        const point_link = document.querySelectorAll(".ct-point-link");
        point_link.forEach(item => {
            item.addEventListener("mouseenter", function () {
                $cursor.addClass('invisible');
            });
            item.addEventListener("mouseleave", function () {
                $cursor.removeClass('invisible');
            });
        });


        const item_list = document.querySelectorAll("header#navigation .others-link .item-list");
        item_list.forEach(item => {
            item.addEventListener("mouseenter", function () {
                $cursor.addClass('invisible');
            });
            item.addEventListener("mouseleave", function () {
                $cursor.removeClass('invisible');
            });
        });


        const point_link_round = document.querySelectorAll(".ct-point-link-round");
        point_link_round.forEach(item => {
            item.addEventListener("mouseenter", function () {
                self.isStuck = true;
                self.w_item = $(item).width();
                self.h_item = $(item).height();
                self.x_item = $(item).offset().left;
                self.y_item = $(item).offset().top;

                $(item).addClass('invisible');
                var text_link = $(item).find('.point-link-round').text();

                gsap.to($cursor, {width: 130, height: 130, 'border-color': '#E30613', 'background-color': 'rgba(31, 31, 31, 0.20)', onComplete: function () {
                        $cursor.html(text_link);
                        self.block = true;
                    }});
            });
            item.addEventListener("mouseleave", function () {
                self.isStuck = false;

                gsap.to($cursor, {duration: .1, width: w_cursor, height: h_cursor, 'border-color': border_cursor, 'background-color': bg_cursor, 'background-image': 'none', onUpdate: function () {
                        $cursor.html('');
                        self.block = false;
                        $cursor.hide();
                        $(item).removeClass('invisible');
                        setTimeout(function () {
                            $cursor.show();
                        }, 150);
                    }});
            });
        });


        const swiper__pdp = document.querySelectorAll(".swiper__pdp");
        swiper__pdp.forEach(item => {
            item.addEventListener("mouseenter", function () {
                //self.isHover = true;
                self.bounds = w_cursor_big;
                gsap.to($cursor, {width: 130, height: 130, 'border-color': '#E1001A', 'background-color': 'rgba(31, 31, 31, 0.20)', onComplete: function () {
                        $cursor.addClass('arrows');
                        $cursor.html('<span class="icon-arrow arrow-left"></span><span class="text-inner">Scorri</span><span class="icon-arrow arrow-right"></span>');
                    }});
            });
            item.addEventListener("mouseleave", function () {
                //self.isHover = false;
                self.bounds = w_cursor;
                $cursor.removeClass('arrows');
                $cursor.html('');
                gsap.to($cursor, {width: w_cursor, height: h_cursor, scale: 1, 'border-color': border_cursor, 'background-color': bg_cursor, onUpdate: function () {
                        $cursor.html('');
                    }});
            });
        });

        const swiper__blog = document.querySelectorAll(".swiper__blog");
        swiper__blog.forEach(item => {
            item.addEventListener("mouseenter", function () {
                //self.isHover = true;
                self.bounds = w_cursor_big;
                gsap.to($cursor, {width: 130, height: 130, 'border-color': '#E1001A', 'background-color': 'rgba(31, 31, 31, 0.20)', onComplete: function () {
                        $cursor.addClass('arrows');
                        $cursor.html('<span class="icon-arrow arrow-left"></span><span class="text-inner">Scorri</span><span class="icon-arrow arrow-right"></span>');
                    }});
            });
            item.addEventListener("mouseleave", function () {
                //self.isHover = false;
                self.bounds = w_cursor;
                $cursor.removeClass('arrows');
                $cursor.html('');
                gsap.to($cursor, {width: w_cursor, height: h_cursor, scale: 1, 'border-color': border_cursor, 'background-color': bg_cursor, onUpdate: function () {
                        $cursor.html('');
                    }});
            });
        });

        const play_video = document.querySelectorAll(".play-video");
        play_video.forEach(item => {
            item.addEventListener("mouseenter", function () {
                $cursor.addClass('invisible');
            });
            item.addEventListener("mouseleave", function () {
                $cursor.removeClass('invisible');
            });
        });

    }
    render() {

        if (!this.block) {

            if (!this.isStuck) {

                this.renderedStyles["tx"].current = mouse.x - this.bounds / 2;
                this.renderedStyles["ty"].current = mouse.y - this.bounds / 2;

                for (const key in this.renderedStyles) {
                    this.renderedStyles[key].previous = lerp(this.renderedStyles[key].previous, this.renderedStyles[key].current, this.renderedStyles[key].amt);
                }

                this.DOM.el.style.transform = `translateX(${this.renderedStyles["tx"].previous}px) translateY(${this.renderedStyles["ty"].previous}px)`;

//            if (!this.isHover) {
//                this.DOM.el.style.transform = `translateX(${this.renderedStyles["tx"].previous}px) translateY(${this.renderedStyles["ty"].previous}px) scale(.45)`;
//            } else {
//                this.DOM.el.style.transform = `translateX(${this.renderedStyles["tx"].previous}px) translateY(${this.renderedStyles["ty"].previous}px) scale(1)`;
//            }

            } else {

                this.DOM.el.style.transform = `translateX(${this.x_item}px) translateY(${this.y_item}px)`;

            }
        }

        requestAnimationFrame(() => this.render());
    }
}



//inizializza cursore
if ($(window).width() > 768) {
    const cursor = new Cursor(document.querySelector(".cursor"));
}



//// INIZIALIZZA SLIDER PDP - (messo qui per aggiornare mouse.x.y al drag) ////
if ($('.swiper__pdp').length > 0) {
    $('.swiper__pdp').each(function (i, el) {
        var $prev = $(el).parents('.pdp__slider').find('.swiper-button-prev')[0];
        var $next = $(el).parents('.pdp__slider').find('.swiper-button-next')[0];
        var $scrollbar = $(el).parents('.pdp__slider').find('.swiper-scrollbar')[0];

        new Swiper($(el)[0], {
            slidesPerView: 1.15,
            spaceBetween: 8,
            disableOnInteraction: true,
            freeMode: {
                enabled: true,
                momentumRatio: 20,
                momentumVelocityRatio: 20
            },
            scrollbar: {
                el: $scrollbar
            },
            navigation: {
                nextEl: $next,
                prevEl: $prev
            },
            on: {
                touchMove: function (e) {
                    mouse.x = e.touches.currentX;
                    mouse.y = e.touches.currentY;
                },
                touchStart: function () {
                    gsap.to($cursor, {width: 100, height: 100});
                },
                touchEnd: function () {
                    gsap.to($cursor, {width: 130, height: 130});
                }
            },
            breakpoints: {
                768: {
                    slidesPerView: "auto",
                    spaceBetween: 24
                }
            }
        });
    });
}


//// INIZIALIZZA SLIDER BLOG - (messo qui per aggiornare mouse.x.y al drag) ////
if ($('.swiper__blog').length > 0) {
    var swiper_blog = [];
    $('.swiper__blog').each(function (i, el) {
        var $prev = $(el).parents('.pdp__slider').find('.swiper-button-prev')[0];
        var $next = $(el).parents('.pdp__slider').find('.swiper-button-next')[0];
        var $scrollbar = $(el).parents('.pdp__slider').find('.swiper-scrollbar')[0];

        swiper_blog[i] = new Swiper($(el)[0], {
            slidesPerView: 1.15,
            spaceBetween: 8,
            disableOnInteraction: true,
            freeMode: {
                enabled: true,
                momentumRatio: 20,
                momentumVelocityRatio: 20
            },
            scrollbar: {
                el: $scrollbar
            },
            navigation: {
                nextEl: $next,
                prevEl: $prev
            },
            on: {
                touchMove: function (e) {
                    mouse.x = e.touches.currentX;
                    mouse.y = e.touches.currentY;
                },
                touchStart: function () {
                    gsap.to($cursor, {width: 100, height: 100});
                },
                touchEnd: function () {
                    gsap.to($cursor, {width: 130, height: 130});
                }
            },
            breakpoints: {
                768: {
                    slidesPerView: "auto",
                    spaceBetween: 24
                }
            }
        });
    });


    //// EVENTO SELETTORE CATEGORIA POST ////
    $('ul.submenu.ui-widget a').on('click', function (e) {
        var $this = $(this);
        if (!$this.hasClass('active')) {
            var cat = $this.parent('li').data('cat').trim();
            e.preventDefault();
            $('ul.submenu.ui-widget a').removeClass('active');
            $this.addClass('active');

            $('.mpblog-post-index .pdp__slider .swiper-slide').hide();
            $('.mpblog-post-index .pdp__slider .swiper-slide').filter(function (index) {
                var cat_slider = $(this).data('cat').trim();
                return cat_slider.indexOf(cat) != -1;
            }).show();

            if (swiper_blog) {
                $.each(swiper_blog, function (i, el) {
                    el.update();
                });
            }

            //funzione comune select trasformata mobile
            if ($(window).width() < 769) {
                var selected_value = $this.parents('li').html();
                var first_li = $this.parents('.list-to-select').find("li:first-child").html();
                $this.parents('.list-to-select').find("li:first-child").html(selected_value);
                $this.parents('li').html(first_li);
                setTimeout(function () {
                    $(".list-to-select").removeClass("open");
                }, 150);
            }
        }
    });
}
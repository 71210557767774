import { focustrap_filters_popup } from './filters.js';

$(window).resize(function () {
    set_menu();
});

set_menu();


function set_menu() {

    if ($(window).width() > 1024) {

        //// POSIZIONE MENU DA SINISTRA ////
        $(window).resize(function () {
            set_menu_left_position();
        });

        set_menu_left_position();
        remove_events_mouse();

        //// MOUSE ////
        $('nav#site-navigation #primary-menu > li > a').on('mouseenter', function () {
            open_menu_desktop(this);
            if (focustrap_filters_popup) {
                focustrap_filters_popup.deactivate();
            }
        });

        $('header#navigation').on('mouseleave', function () {
            close_menu_desktop();
        });

        $('.close-menu-desktop button').on('click', function () {
            close_menu_desktop();
        });


        //// MOUSE HOVER EFFETTO ICONE TESTO ////
        $('header#navigation .others-link .item-list').on('mouseenter', function () {
            var $this = $(this);
            var label = '';
            if ($this.children('a').length > 0) {
                label = $this.children('a').attr('aria-label');
            } else {
                label = $this.children('button').attr('aria-label');
            }
            $('header#navigation .others-link .item-list').not(this).addClass('opacity');
            $this.append('<span class="label-over">' + label + '</span>');
        });

        $('header#navigation .others-link .item-list').on('mouseleave', function () {
            $('header#navigation .others-link .item-list').removeClass('opacity');
            $('.item-list .label-over').remove();
        });


        //// TAB ////
        $('nav#site-navigation #primary-menu > li > a').on('focus', function () {
            open_menu_desktop(this);
        });

    } else {

        $('header#navigation').show();
        remove_events_mouse();
        $(".open-menu").off("click");
        $('nav#site-navigation #primary-menu > li > a').off('click');
        $('.back-menu').off('click');

        $('nav#site-navigation #primary-menu > li > a').on('click', function () {
            var $this = $(this);
            $('nav#site-navigation #primary-menu > li > a').addClass('hide');
            $('.others-link-mobile').addClass('hide');
            $('.social-link-mobile').addClass('hide');
            $('.back-menu').addClass('view');
            $this.siblings('.sub-menu').addClass('view');
            setTimeout(function () {
                $this.siblings('.sub-menu').addClass('open-mobile');
            }, 50);
        });

        $('.back-menu').on('click', function () {
            $('.sub-menu.view').removeClass('open-mobile');
            setTimeout(function () {
                $('.sub-menu.view').removeClass('view');
                $('nav#site-navigation #primary-menu > li > a').removeClass('hide');
                $('.others-link-mobile').removeClass('hide');
                $('.social-link-mobile').removeClass('hide');
                $('.back-menu').removeClass('view');
            }, 300);
        });


        //// HAMBURGER ////
        $(".open-menu").on("click", function (e) {
            e.stopPropagation();
            console.log('s')
            var $this = $(this);
            var menu_open = $this.data("is_open");

            if (!menu_open) {
                $this.addClass("is-active");
                $this.data("is_open", true);
                $("header#navigation").addClass("open");
                $("nav#site-navigation").addClass("open");
                $('header#navigation').addClass('unify-bg');
                $("body").addClass("overflow-hidden");
                //$("header").addClass("open-mobile");
                //$(".site-branding").addClass("hidden");
                $("header").removeClass("headroom--unpinned");
            } else {
                $this.removeClass("is-active");
                $this.data("is_open", false);
                $("header#navigation").removeClass("open");
                $("nav#site-navigation").removeClass("open");
                $('header#navigation').removeClass('unify-bg');
                //$("header").removeClass("open-mobile");
                //$(".site-branding").removeClass("hidden");
                $("body").removeClass("overflow-hidden");
            }
        });
    }
}


$('nav#site-navigation #primary-menu > li > a').on('click', function (e) {
    e.preventDefault();
});


function open_menu_desktop(self) {
    $("body").addClass("overflow-hidden");
    $('nav#site-navigation .sub-menu').removeClass('open');
    $(self).siblings('.sub-menu').addClass('open');
    $('header#navigation').addClass('unify-bg');
    $('.others-link .item-list:not(.icon-search)').hide();
    $('.close-menu-desktop').show();
}

function close_menu_desktop() {
    $("body").removeClass("overflow-hidden");
    $('nav#site-navigation .sub-menu').removeClass('open');
    $('header#navigation').removeClass('unify-bg');
    $('.others-link .item-list:not(.ct-hamburger-menu)').show();
    $('.close-menu-desktop').hide();
}

function set_menu_left_position() {
    var left_footer = $('.js-align-menu').offset().left + 10;
    $('nav#site-navigation').css('left', left_footer);
    $('nav#site-navigation .sub-menu').css('padding-left', left_footer);
    $('header#navigation').show();
    $('.sales-guest-form main').css('padding-left', left_footer);
    $('.checkout-onepage-success  main').css('padding-left', left_footer);
    $('#amsearch-result-tabs .data.content').css('padding-left', left_footer);
    $('.catalogsearch-result-index .page-title').css('padding-left', left_footer);
    $('.data.item.title:first-child').css('padding-left', left_footer);
    $('.mpblog-post-view .mp-blog-view .page-title,.mpblog-post-view  .breadcrumbs,.mpblog-post-view .mp-blog-view p,.mpblog-post-view .mp-blog-view h2,.mpblog-post-view .cms-content-important,.mpblog-post-view .mp-blog-view h5 ').css('padding-left', left_footer);
    $('.mpblog-post-view .mp-blog-view p,.mpblog-post-view .mp-blog-view h2,.cms-content-important,.mpblog-post-view h5').css('padding-right', left_footer);
    // $('.newsletter--signin').css('padding-left', left_footer);
    // $('.newsletter--signin').css('padding-right', left_footer);
    
    
    
}

function remove_events_mouse() {
    $('nav#site-navigation #primary-menu > li > a').off('mouseenter');
    $('header#navigation').off('mouseleave');
}
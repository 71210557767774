const projectTriggers = document.querySelectorAll(".trigger_animation ");

projectTriggers.forEach(addTimeline);

function addTimeline(project, index) {
  // const image = project.querySelector(".full-cover");

  if ($(window).width() < 1025) {
 
 }
 else {
 


  const text = project.querySelector(".subtitle");
  const link = project.querySelector(".link-arrow");
  const linkText = project.querySelector(".text-2");
 
  

//   ScrollTrigger.create({
//     trigger: project,
//     start: "top 75%",
//     end: "bottom 25%",
//     start: "top center",
 
//     end: "bottom 80%",
//     ease: "power2",
//     markers: true,
//     onEnter: () => timeline.play(),
//     // onLeave: () => timeline2.duration(2).play(),
//     // onEnterBack: () => action.duration(0.5).play(0),
//     // onLeaveBack: () => action.pause(0)
//   });

  const timeline = gsap.timeline({
    scrollTrigger: {
      trigger: project,
 
      start: "top center",
 
      ease: "ease:ExpoScaleEase.config(0.5, 3, Power2.easeIn)",
    //   scrub: true,
      toggleActions: "play none none reverse"
    }
  })
  .from(projectTriggers, {
    y: 200,
opacity:0,
    duration: 1,
    stagger: 0.2
  })
 
  .from(text, {
    y: 200,
opacity:0,
    duration: 1,
    stagger: 0.2
  }, "-=0.5")

  .from(link, {
    y: 200,
    opacity:0,
    duration: 1,
 
  }, "-=0.5")
  .from(linkText, {
    y: 400,
    opacity:0,
    duration: 1,
   
  }, "-=0.5")
}

 
}

if ($('.checkout-index-index').length > 0) {

    //// IMPOSTA ALTEZZA CHECKOUT SUMMARY ////
    //set_check_summary_height();

    $(window).resize(function () {
        //set_check_summary_height();
    });

//    function set_check_summary_height() {
//        if ($(window).width() > 768) {
//            $('.opc-summary-wrapper').height($('.checkout-container').outerHeight() + 15);
//        }
//    } 

    $('.checkout-index-index').on('click', '.estimated_summary_span', function () {
        $("body").addClass("overflow-hidden");
        $('.opc-sidebar.opc-summary-wrapper').fadeIn('fast');
    });

    $('.checkout-index-index').on('click', '.opc-sidebar .action-close', function () {
        $("body").removeClass("overflow-hidden");
        $('.opc-sidebar.opc-summary-wrapper').fadeOut('fast');
    });

    $('.checkout-index-index').on('click', '.open-password-field', function () {
        $('.form.form-login .fieldset.hidden-fields').addClass('add-visible');
    });

}
import Swiper from 'swiper/bundle';
import Headroom from "headroom.js";
import * as focusTrap from 'focus-trap';

//var _BREAKPINT_MOBILE = 768;
//var _BREAKPINT_MEDIUM = 991;



//// INIZIALIZZA HEADROOM ////
var header = document.querySelector(".site-header");
var headroom = new Headroom(header);
try {
    headroom.init();
} catch (e) {
}



//// INIZIALIZZA SLIDER swiper__from_cols ////
if ($(window).width() < 769) {
    if ($('.swiper__from_cols').length > 0) {
        $('.swiper__from_cols').each(function (i, el) {
            new Swiper($(el)[0], {
                slidesPerView: 1.2,
                spaceBetween: 8,
                slidesPerGroup: 1,
                disableOnInteraction: true,
                observer: true,
                observeParents: true
            });
        });
    }
}


//// POSIZIONE PALLINI ////
$('.ct-point').each(function (i, el) {
    var $this = $(el);
    var y = $this.data('position-y');
    var x = $this.data('position-x');
    $this.css({top: y, left: x});
});


//// ACCENDE SPEGNE LUCE ////
$('.ct-point-light').on('mouseenter', function () {
    var $point_light = $(this).find('.point-light');
    $point_light.addClass('active');
    if (!$point_light.hasClass('off')) {
        $point_light.find('.text-off').hide();
        $point_light.find('.text-on').show();
    } else {
        $point_light.find('.text-on').hide();
        $point_light.find('.text-off').show();
    }
}).on('mouseleave', function () {
    var $point_light = $(this).find('.point-light');
    $point_light.removeClass('active');
    $point_light.find('.text-light').hide();
});

$('.ct-point-light').on('click', function () {
    var $point_light = $(this).find('.point-light');
    var $el;
//    if ($this.parents('.light-sensitive').length > 0) {
//        $el = $this.parents('.light-sensitive');
//    } else {
//        $el = $this.parents('section').find('.light-sensitive');
//    }
    $el = $point_light.parents('.light-sensitive');

    if (!$point_light.hasClass('off')) {
        $point_light.addClass('off');
        $el.find('img.top').addClass('transparent');
        $point_light.find('.text-on').hide();
        $point_light.find('.text-off').show();
    } else {
        $point_light.removeClass('off');
        $el.find('img.top').removeClass('transparent');
        $point_light.find('.text-off').hide();
        $point_light.find('.text-on').show();
    }
});


//// MOSTRA LINK ////
$('.ct-point-link').on('mouseenter', function () {
    $(this).addClass('active');
}).on('mouseleave', function () {
    $(this).removeClass('active');
});


//// EVENTO APERTURA SELECT MOBILE (DA LISTA) ////
if ($(window).width() < 769) {
    $(".list-to-select").on('click', function () {
        var $this = $(this);
        var is_open = $this.hasClass("open");
        if (is_open) {
            $this.removeClass("open");
        } else {
            $this.addClass("open");
        }
    });
    //    $(".list-to-select li").on('click', 'button a', function () {
    //        var selected_value = $(this).parents('li').html();
    //        console.log(selected_value)
    //        var first_li = $(".list-to-select li:first-child").html();
    //        $(".list-to-select li:first-child").html(selected_value);
    //        $(this).parents('li').html(first_li);
    //    });
    //$(document).on('mouseup', function (event) {
    //    var target = event.target;
    //    var select = $(".list-to-select");
    //    if (!select.is(target) && select.has(target).length === 0) {
    //        select.removeClass("open");
    //    }
    //});
}


//// VIDEO PLAY PAUSE ////
$('.video-controls').on('click', function () {
    if (this.paused) {
        this.play();
        $('.cursor').html('Pause Video');
    } else {
        this.pause();
        $('.cursor').html('Play Video');
    }
});


//// APRE VIDEO POPUP ////
var focustrap_video_popup = focusTrap.createFocusTrap('.popup-video', {returnFocusOnDeactivate: false});
$('.open-video-popup, .ct-point-video').on('click focus', function () {
    var video_src = $(this).data('video-src');
    //$('.popup-video iframe').attr('src', video_src);
    $('.popup-video video').attr('src', video_src);
    $("body").addClass("overflow-hidden");
    $('header#navigation').hide();
    $('.popup-video').fadeIn();
    focustrap_video_popup.activate();
});

//// CHIUDE VIDEO POPUP ////
$('.popup-video .close-popup').on('click', function () {
    $("body").removeClass("overflow-hidden");
    $('header#navigation').show();
    $('.popup-video').fadeOut();
    //$('.popup-video iframe').attr('src', '');
    $('.popup-video video').attr('src', '');
    focustrap_video_popup.deactivate();
});



//// AGGIUNGE ELEMENTO PER STILARE CHECKOX ////
$('input[type="checkbox"]').each(function (i, el) {
    if ($(el).parent('.field.choice').length > 0) {
        if ($(el).parent('.field.choice').find('.checkmark').length < 1) {
            $(el).parent('.field.choice').append('<span class="checkmark"></span>');
        }
    }
    if ($(el).parent('.col-method').length > 0) {
        if ($(el).parent('.col-method').find('.checkmark').length < 1) {
            $(el).parent('.col-method').append('<span class="checkmark"></span>');
        }
    }
    console.log('checkmark') 
});

//// AGGIUNGE ELEMENTO PER STILARE RADIO ////       //TODO - TEST PERCHE ANCORA NON VEDO RADIO NELLE PAGINE
$('input[type="radio"]').each(function (i, el) {
    if ($(el).parent('.field.choice').length > 0) {
        if ($(el).parent('.field.choice').find('.checkmark').length < 1) {
            $(el).parent('.field.choice').append('<span class="checkmark"></span>');
        }
    }
    if ($(el).parent('.col-method').length > 0) {
        if ($(el).parent('.col-method').find('.checkmark').length < 1) {
            $(el).parent('.col-method').append('<span class="checkmark"></span>');
        }
    }
    console.log('checkmark')
});


//// SCROLL TOP ////
$('.go-to-top').on('click', function () {
    $("html, body").animate({scrollTop: 0}, 1500);
});



//// SCROLL ANCORE ////
//var space = 80;
//if ($(".menu-anchor").length > 0) {
//    var full_url = window.location.href;
//
//    if (full_url.indexOf("#") !== -1) {
//        var parts = full_url.split("#");
//        var target = parts[1];
//
//        if (!$('.menu-anchor').hasClass('menu-anchor-submenu')) {
//            space = 0;
//        }
//
//        scroll_page_anchor(full_url, space);
//
//        $(".menu-anchor li").each(function () {
//            var href = $(this).children("a").attr("href");
//            if ("#" + target === href) {
//                $(this).addClass("active");
//            }
//        });
//    }
//}
//
//$(".menu-anchor a, .link-anchor-contact").on("click", function (e) {
//    e.preventDefault();
//
//    if ($(this).hasClass('link-anchor-contact')) {
//        space = 0;
//    }
//
//    if (!$('.menu-anchor').hasClass('menu-anchor-submenu')) {
//        space = 0;
//    }
//
//    scroll_page_anchor(this.href, space);
//    $(this).parent("li").addClass("active");
//});
//
//function scroll_page_anchor(full_url, space) {
//    var parts = full_url.split("#");
//    var target = parts[1];
//    var space_top = 80 + space;
//
//    console.log(space)
//    console.log(space_top)
//
//    if (target !== '' && $('[data-anchor="' + target + '"]').length > 0) {
//        $(".menu-anchor li").removeClass("active");
//        var target_offset = $('[data-anchor="' + target + '"]').offset();
//        var target_top = target_offset.top;
//        $("html, body").animate({scrollTop: target_top - space_top}, 1500);
//    }
//}

import * as focusTrap from 'focus-trap';
//import Swiper from 'swiper/bundle';


$("html, body").scrollTop(0);


//// APPEND ////

$('.page-title-wrapper').prepend($('#subtitle-funzione'));
$('.product-info-price').prepend($('#subtitle-vat'));

if ($(window).width() < 769) {
    $('#tab_buy > .row').prepend($('.ct-title-price'));
}

$('.box-event-bottom .ct-info-bottom').append($('.product-social-links'));



//// AGGIUNGE CLASSE opaque alla prima immagine della hero //// 

$('.pdp__first_block .ct-images img:first-child').addClass('opaque');



var _ENABLE_CLICK_TAB = false;
$(document).on('swatch.initialized', function (e) {


    //// CONTENUTO TAB BUY VISIBILE DOPO PER PROBLEMA JS FOTORAMA ////
    if ($('.tab-content#tab_buy').length > 0) {
        setTimeout(function () {
            $('.tab-content#tab_buy').hide();
            _ENABLE_CLICK_TAB = true;
        }, 1200);
    }

    //// AGGIUNGE CLASSE list-colors ////                   TODO - aggiungere se serve su altre varianti
    if ($('#product-options-wrapper').length > 0) {
        setTimeout(function () {
            $('#product-options-wrapper .swatch-attribute.color .swatch-attribute-options').addClass('list-colors');
            $('#product-options-wrapper .swatch-attribute.coloremontatura .swatch-attribute-options').addClass('list-colors');
        }, 1000);
    }
});



//// EVENTO PLAY VIDEO SU GALLERY ////

$('.play-video').on('click', function () {
    var $this = $(this);
    var $video = $this.siblings('video');
    $this.siblings('img').addClass('remove-opacity');
    $video.fadeIn();
    console.log($video[0].paused)
    if ($video[0].paused) {
        $this.addClass('pause');
        $video[0].play();
    } else {
        $this.removeClass('pause');
        $video[0].pause();
    }
});



//// EVENTO CAMBIO IMMAGINE PALLINI E SELECTED DA HERO //// 

$('main').on('click', '.ct-list-colors-pdp .swatch-option', function () {
    var $this = $(this);
    var id = $this.data('option-id');
    change_images_hero(id);

    $('.ct-list-colors-pdp .swatch-option').removeClass('selected');
    $this.addClass('selected');

    var $swatch_option = $('.product-info-main .swatch-attribute.color .swatch-option[data-option-id="' + id + '"]');
    $('.product-info-main .swatch-attribute.color .swatch-option').removeClass('selected').attr('aria-checked', false);
    $('.product-info-main .swatch-attribute.color').data('option-selected', id);
    $swatch_option.addClass('selected').attr('aria-checked', true).trigger('click');
    $('.product-info-main .swatch-attribute.color .swatch-attribute-options').attr('aria-activedescendant', $swatch_option.attr('id'));
    
    console.log($swatch_option.attr('id'))
});



//// EVENTO CAMBIO IMMAGINE PALLINI E SELECTED DA PRODUCT INFO MAIN ////

$('main').on('click', '.product-info-main .swatch-attribute.color .swatch-option', function () {
    var $this = $(this);
    var id = $this.data('option-id');
    change_images_hero(id);

    $('.product-info-main .swatch-attribute.color .swatch-option').removeClass('selected');
    $this.addClass('selected');

    $('.ct-list-colors-pdp .swatch-option').removeClass('selected');
    $('.ct-list-colors-pdp .swatch-option[data-option-id="' + id + '"]').addClass('selected');
});

function change_images_hero(id) {
    console.log(id)
    $('.pdp__first_block .ct-images img').removeClass('opaque');
    $('.pdp__first_block .ct-images img').each(function (i, el) {
        var id_src = $(el).attr('src').split('__________')[1].split('.')[0];
        if (id == id_src) {
            $(el).addClass('opaque');
        }
    });
}



//// EVENTO TAB PDP ////

$('.pdp__tab').on('click', 'button', function () {
    if (_ENABLE_CLICK_TAB) {
        var $this = $(this);
        var tab = $this.data('tab');
        $('.pdp-tab-main li').removeClass('active');
        $this.parents('li').addClass('active');
        $('#product-addtocart-button').hide();
        if (tab == 'specs' || tab == 'buy') {
            $('.pdp-tab-main, .ct-breadcrumb-tab .breadcrumbs').addClass('black');
            $('.pdp__tab').addClass('stopAnimation')
            console.log('animazion no')
        } else {
            $('.pdp-tab-main, .ct-breadcrumb-tab .breadcrumbs').removeClass('black');
            // $('.pdp__tab').css('position','').css('transform','')
            $('.pdp__tab').removeClass('stopAnimation')
            console.log('animazion yes')
        }
        if (tab == 'buy') {
            set_addtocart_position();
        }

        if ($(window).width() < 769) {
            $('.pdp-tab-main li').show();
            if (tab == 'overview' || tab == 'specs') {
                $this.parents('li').hide();
                $('.pdp-tab-main .button-buy').removeClass('icon');
                $('#product-addtocart-button').removeClass('icon');
            }
        }

        $("html, body").scrollTop(0);

        $('.pdp__tab_content .tab-content').hide();
        $('.pdp__tab_content .tab-content#tab_' + tab).fadeIn();
        ScrollTrigger.refresh()
        console.log('scrolltrigger refresh', ScrollTrigger.refresh())
    }
});


$(window).resize(function () {
    set_addtocart_position();
});

function set_addtocart_position() {
    if ($('#product-addtocart-button').length > 0) {
        if ($(window).width() > 768) {
            var top_button = $('.pdp-tab-main .button-buy').offset().top;
            var left_button = $('.pdp-tab-main .button-buy').offset().left;
        } else {
            $('.pdp-tab-main .button-buy').addClass('icon');
            $('#product-addtocart-button').addClass('icon');
            var top_button = $('.pdp-tab-main .button-buy').offset().top;
            var left_button = $('.pdp-tab-main .button-buy').offset().left;
        }
        $('#product-addtocart-button').css({
            top: top_button,
            left: left_button
        }).show();
    }
}



//// EVENTO AGGIUNGI AL CARRELLO PULSANTE CLONATO ////

if ($('#product-addtocart-button').length > 0) {
    $('#product-addtocart-button').clone().appendTo('.ct-add-to-cart');

    $('.ct-add-to-cart #product-addtocart-button').on('click', function () {
        $('.product-add-form form').submit();
    });
}



//// EVENTO PREOPRDINE POPUP ////

var focustrap_preorder_popup = focusTrap.createFocusTrap('.popup-preorder', {returnFocusOnDeactivate: false});
$('.button-preorder').on('click', function () {
    $('header#navigation').hide();
    $("body").addClass("overflow-hidden");
    $('.pdp__tab_content .layer').fadeIn();
    $('.popup-preorder').addClass('active');
    focustrap_preorder_popup.activate();
});

$('.close-preorder').on('click', function () {
    $('header#navigation').show();
    $("body").removeClass("overflow-hidden");
    $('.pdp__tab_content .layer').fadeOut();
    $('.popup-preorder').removeClass('active');
    focustrap_preorder_popup.deactivate();
});


//// SELEZIONE LISTA VARIANTI ////

if ($(window).width() > 768) {
    $(".select_model .list-to-select").on('click', function () {
        var $this = $(this);
        var is_open = $this.hasClass("open");
        if (is_open) {
            $this.removeClass("open");
        } else {
            $this.addClass("open");
        }
    });
}

$('.select_model').on('click', '.change-variant', function () {
    var $this = $(this);
    $('.select_model .change-variant').removeClass('active');
    $this.addClass('active');
    var variant = $this.data('variant');

    //funzione comune select trasformata mobile
    var selected_value = $this.parents('li').html();
    var first_li = $this.parents('.list-to-select').find("li:first-child").html();
    $this.parents('.list-to-select').find("li:first-child").html(selected_value);
    $this.parents('li').html(first_li);
    setTimeout(function () {
        $(".list-to-select").removeClass("open");
    }, 150);

    $('.ct-version').hide();
    $('.ct-version[data-variant="' + variant + '"]').fadeIn('fast');
});


$('.list-certifications img').on('mouseenter', function () {
    $(this).siblings('.tip-cert').fadeIn('fast');
}).on('mouseleave', function () {
    $(this).siblings('.tip-cert').fadeOut('fast');
});



//var stickyEl = new Sticksy('.product.media', {
//    topSpacing: 0,
//});
//stickyEl.onStateChanged = function (state) {
//    if (state === 'fixed')
//        stickyEl.nodeRef.classList.add('widget--sticky')
//    else
//        stickyEl.nodeRef.classList.remove('widget--sticky')
//}
let projectTrigger = document.querySelectorAll('.concept__first_block');

projectTrigger.forEach(project => {
    let timeline = gsap.timeline({
        scrollTrigger: {
            trigger: ".concept__first_block",
            start: "center bottom",
            ease: "power2.easeOut",
            toggleActions: "play none none reverse",
        }
    })

    timeline
        .to(".concept__first_block", {
            opacity: 1,
            stagger: 0.2
        }, "")
        .from(".concept__first_block .join-line", {
            height: 0,

            duration: 0.5,
            stagger: 0.2
        }, "")
        .from(".concept__first_block  .text-5", {
            y: 50,
            opacity: 0,
            duration: 0.5
        }).from(".concept__first_block .text-2", {
            y: 50,
            opacity: 0,
            duration: 0.5,
            stagger: 0.2
        }, "-=0.5")
        .from(".concept__first_block .video-controls", {
            y: 50,
            opacity: 0,
            duration: 0.5,
            stagger: 0.2
        }, "-=0.5")


})
import * as focusTrap from 'focus-trap';


//// EVENTI APERTURA CHIUSURA POPUP FILTRI RASSEGNA ////

var _HEADER_WHITE = false;
if ($('header#navigation').hasClass('bg--white')) {
    _HEADER_WHITE = true;
}

var focustrap_filters_rassegna = focusTrap.createFocusTrap('.popup-filters-rassegna', {returnFocusOnDeactivate: false});
$('.view-filters-rassegna').on('click', function () {
    $("body").addClass("overflow-hidden");

    if ($(window).width() > 768) {
        $('.popup-filters-rassegna .close-filters').appendTo('.popup-filters-rassegna .sidebar.sidebar-main').show();
    } else {
        $('.popup-filters-rassegna .close-filters').appendTo('.popup-filters-rassegna .ct-actions .inner-actions').show();
    }
    //

    $('.popup-filters-rassegna').fadeIn('fast');
    focustrap_filters_rassegna.activate();

    if (!_HEADER_WHITE) {
        $('header#navigation').addClass('bg--white');
    }
});

$('.popup-filters-rassegna .close-filters, .popup-filters-rassegna .view-results').on('click', function () {
    $("body").removeClass("overflow-hidden");
    $('.popup-filters-rassegna').fadeOut('fast');
    focustrap_filters_rassegna.deactivate();

    if (!_HEADER_WHITE) {
        $('header#navigation').removeClass('bg--white');
    }
});


//// EVENTI FILTRI ////

if ($('.rassegna-item').length > 0) {

    var rassegna_length = $('.rassegna-item').length;
    $('.count-total-results.count-total-rassegna').text(rassegna_length);

    $('.popup-filters-rassegna .filter-options-title').on('click', function () {
        var $this = $(this);
        $('.popup-filters-rassegna .filter-options-title').removeClass('active');
        $('.popup-filters-rassegna .filter-options-content').removeClass('active');
        $this.addClass('active');
        $this.siblings('.filter-options-content').addClass('active');
    });

    var filters_selected = [];
    var h_item = 48;
    $('.popup-filters-rassegna').on('click', '.filter-button', function () {
        var $this = $(this);
        var filter = $this.data('filter');

        if (!$this.hasClass('active')) {
            $this.addClass('active');
            filters_selected.push(filter);
            $('.popup-filters-rassegna .filter-actions, .ct-filters-selected .filter-actions').show();
            $('.popup-filters-rassegna .count-selected-filters').text(filters_selected.length);

            $('.popup-filters-rassegna .filter-current .items, .selected-filters .filter-current .items').append('<li class="item">'
                    + '<span class="filter-value">' + $this.text() + '</span>'
                    + '<button class="action remove remove-filter-rassegna" data-filter="' + filter + '" title="Remove ' + $this.text() + '"><span>Remove This Item</span></button>'
                    + '</li>');
        }

        $('.rassegna-item').hide();
        $('.rassegna-item').filter(filters_selected.join('')).show();     // Filter elements: class1 AND class2
        //$('.rassegna-item').filter(filters_selected.join()).show();     //Filter elements: class1 OR class2
        $('.count-total-results.count-total-rassegna').text($('.rassegna-item:visible').length);

        toggle_load_more('hide');

        console.log(filters_selected)
    });

    $('.selected-filters').on('click', '.remove-filter-rassegna', function () {
        var $this = $(this);
        var filter = $this.data('filter');
        $('.popup-filters-rassegna .filter-button[data-filter="' + filter + '"]').removeClass('active');
        $('.filter-current .item').each(function (i, el) {
            if (filter == $(el).find('.remove-filter-rassegna').data('filter')) {
                $(el).remove();
            }
        });
        filters_selected = filters_selected.filter(function (item) {
            return item !== filter
        });

        if (!filters_selected.length == 0) {
            $('.rassegna-item').hide();
            $('.rassegna-item').filter(filters_selected.join('')).show();     // Filter elements: class1 AND class2
            //$('.rassegna-item').filter(filters_selected.join()).show();     //Filter elements: class1 OR class2
            $('.count-total-results.count-total-rassegna').text($('.rassegna-item:visible').length);
        } else {
            $('.rassegna-item').show();
            $('.count-total-results.count-total-rassegna').text(rassegna_length);
        }

        $('.popup-filters-rassegna .count-selected-filters').text(filters_selected.length);
        if (filters_selected.length == 0) {
            $('.popup-filters-rassegna .filter-actions, .ct-filters-selected .filter-actions').hide();
        }

        toggle_load_more('hide');

        console.log(filters_selected)
    });

    $('.clear-filter-rassegna').on('click', function () {
        filters_selected = [];
        $('.popup-filters-rassegna .filter-button').removeClass('active');
        $('.popup-filters-rassegna .filter-actions, .ct-filters-selected .filter-actions').hide();
        $('.popup-filters-rassegna .count-selected-filters').text(0);
        $('.popup-filters-rassegna .selected-filters .items, .ct-filters-selected .selected-filters .items').html('');
        $('.rassegna-item').show();
        $('.count-total-results.count-total-rassegna').text(rassegna_length);
        toggle_load_more('hide');
        console.log(filters_selected)
    });



    //// ACTIVE DEFAULT PRIME VOCI POPUP ////

    if ($('.popup-filters-rassegna').length > 0) {
        $('.popup-filters-rassegna .filter-options .filter-options-item:first-child .filter-options-title').addClass('active');
        $('.popup-filters-rassegna .filter-options .filter-options-item:first-child .filter-options-content').addClass('active');
    }



    //// EVENTO CARICA ALTRE RASSEGNE ////

    var view_rassegna = 12;
    $('.load-more-rassegna').on('click', function () {
        var visible_items = [];
        view_rassegna += 8;

        $('.rassegna-item:nth-child(-n+' + view_rassegna + ')').css('display', 'flex');

        $('.rassegna-item').each(function (i, el) {
            visible_items[i] = $(el).is(':visible');
        });

        //console.log(visible_items)

        if (check_all_true(visible_items)) {
            $(this).hide();
        }
    });
}

function toggle_load_more(action) {
    if (action == 'hide') {
        $('.load-more-rassegna').hide();
    }
}

function check_all_true(array) {
    return array.every(function (element) {
        return element === true;
    });
}



//// TENDINA ORDINA RISULTATI ////

if ($(window).width() > 768) {
    $(".press--page .list-to-select").on('click', function () {
        var $this = $(this);
        var is_open = $this.hasClass("open");
        if (is_open) {
            $this.removeClass("open");
        } else {
            $this.addClass("open");
        }
    });
}

var count = 0;
$('.press--page .list-to-select').on('click', '.btn-order', function () {
    var $this = $(this);
    var rassegne = [];
    var order = $(this).data('order');

    $('.rassegna-item').each(function (i, el) {
        var date = $(el).data('date');
        rassegne.push({
            html: $(el)[0].outerHTML,
            date: date
        });
    });

    if (order == 'desc') {
        rassegne.sort(function (a, b) {
            return new Date(b.date) - new Date(a.date);
        });
    } else if (order == 'asc') {
        rassegne.sort(function (a, b) {
            return new Date(a.date) - new Date(b.date);
        });
    }

    var new_rassegne = [];
    for (var i = 0; i < rassegne.length; i++) {
        new_rassegne[i] = rassegne[i].html;
    }
    $('.press-row').html(new_rassegne.join(''));

    if (count == 0) {
        $('.press--page .list-to-select.order-by li:first-child').remove();
    }
    count++;

    //funzione comune select trasformata mobile
    var selected_value = $this.parents('li').html();
    var first_li = $this.parents('.list-to-select').find("li:first-child").html();
    $this.parents('.list-to-select').find("li:first-child").html(selected_value);
    $this.parents('li').html(first_li);
    setTimeout(function () {
        $(".list-to-select.order-by").removeClass("open");
    }, 150);
});



//// TAB COMUNICATI STAMPA ////

var view_comunicati = 6;
var number_comunicati = 0;
var tid = $('.list-comunicati li.active').data('press-trigger');
$('.press_row__comunicati .rassegna-item').hide();
$('.press_row__comunicati .rassegna-item[data-press-id="' + tid + '"]').each(function (i, el) {
    if (i < view_comunicati) {
        $(el).show();
    }
    number_comunicati++;
});
$('.count-total-results.count-total-comunicati').text(number_comunicati);

$('.list-comunicati').on('click', 'li', function () {
    var $this= $(this);
    number_comunicati = 0;
    tid = $(this).data('press-trigger');
    $('.list-comunicati li').removeClass('active');
    $(this).addClass('active');
    $('.press_row__comunicati .rassegna-item').hide();
    $('.press_row__comunicati .rassegna-item[data-press-id="' + tid + '"]').each(function (i, el) {
        if (i < view_comunicati) {
            $(el).show();
        }
        number_comunicati++;
    });
    $('.count-total-results.count-total-comunicati').text(number_comunicati);

    //funzione comune select trasformata mobile
    if ($(window).width() < 769) {
        var selected_value = $this.html();
        var first_li = $this.parents('.list-to-select').find("li:first-child").html();
        $this.parents('.list-to-select').find("li:first-child").html(selected_value);
        $this.html(first_li);
        setTimeout(function () {
            $(".list-to-select.list-comunicati").removeClass("open");
        }, 150);
    }
});


//// EVENTO CARICA ALTRI COMUNICATI ////

$('.load-more-comunicati').on('click', function () {
    var visible_items = [];
    view_comunicati += 6;

    $('.press_row__comunicati .rassegna-item[data-press-id="' + tid + '"]').each(function (i, el) {
        if (i < view_comunicati) {
            $(el).show();
        }
    });

    $('.press_row__comunicati .rassegna-item[data-press-id="' + tid + '"]').each(function (i, el) {
        visible_items[i] = $(el).is(':visible');
    });

    console.log(visible_items)

    if (check_all_true(visible_items)) {
        $(this).hide();
    }
});
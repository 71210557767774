//click handler for album-trigger
$('.nologged').on('click', function (e) {
 e.preventDefault()
    $('.customer-login-link').trigger('click')
  })
  $('.loggedGeneral').on('click', function (e) {
    e.preventDefault()
     $('.openAdvice').fadeIn();
})

  

if ($(".smile_custom_entity-set-view")[0]){
  // Do something if class exists
  $('.field-gruppo_customer').hide()
  
  $('.select-items div[data-value="82"]').trigger('click').addClass('isSelected')

} else {
  // Do something if class does not exist
  $('.pressMssg').hide();
}

//

$('.customer--submenu li').on('click', function () {
    if (!$(this).hasClass('active')) {
        var tid = $(this).data('guide-trigger');
        $('.customer--submenu li').removeClass('active');
        $(this).addClass('active');
        var $current = $('.customer-guide[data-guide-id="' + tid + '"]').toggle();
        $('.customer-guide').not($current).hide();

        if ($(window).width() < 769) {
            if ($('.info-menu-mobile .txt-menu-second').length > 0) {
                $('.info-menu-mobile .txt-menu-second').remove();
            }
            $('.txt-menu').addClass('other');
            $('.info-menu-mobile').append('<span class="txt-menu-second">' + $(this).text() + '</span>');
        }
    }
});


///// MENU MOBILE /////

if ($(window).width() < 769) {
    $('.info-menu-mobile').html('<span class="txt-menu">' + $('.main-customer-menu > li > a.active').text() + '</span>');

    $('.customer-menu').on('click', '.info-menu-mobile', function () {
        if (!$('.main-customer-menu').is(':visible')) {
            $(this).siblings('.main-customer-menu').show();
            $("body").addClass("overflow-hidden");
            $('.customer-menu').addClass('block');
        } else {
            $(this).siblings('.main-customer-menu').hide();
            $("body").removeClass("overflow-hidden");
            $('.customer-menu').removeClass('block');
        }
    });
}
//import $ from 'jquery';
import 'what-input';

// Foundation JS relies on a global variable. In ES6, all imports are hoisted
// to the top of the file so if we used `import` to import Foundation,
// it would execute earlier than we have assigned the global variable.
// This is why we have to use CommonJS require() here since it doesn't
// have the hoisting behavior.
window.$ = $;
window.jQuery = $;

require('foundation-sites');

// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below

import './lib/foundation-explicit-pieces';


//$(document).foundation();


jQuery(function ($) {
    
    require('./modules/listbox.js');
    require('./modules/listbox-expandable.js');
    require('./modules/select.js');
    require('./modules/accordion.js');
    require('./modules/cursor.js');

    require('./modules/common.js');
    require('./modules/menu.js');
    require('./modules/filters.js');
    require('./modules/ajaxcomplete.js');
    require('./modules/popup_gallery.js');
    require('./modules/homepage.js');
    require('./modules/collection.js');
    require('./modules/concept.js');
    require('./modules/professionals.js');
    require('./modules/ambient.js');
    require('./modules/pdp.js');
    require('./modules/cart.js');
    require('./modules/catalogs.js');
    require('./modules/checkout.js');
    require('./modules/store_locator.js');
    
    require('./modules/animations/twoColl.js');
    require('./modules/animations/twoColimgLink.js');
    require('./modules/animations/bgSubTitle.js');
    require('./modules/animations/twoCollBg.js');
    require('./modules/animations/bgTextLink.js');
    require('./modules/animations/selectorType.js');
    require('./modules/animations/projectsFade.js');
    require('./modules/animations/fadeProducts.js');
    require('./modules/animations/conceptFadeInVariants.js');
    require('./modules/animations/fadeConceptSingleHero.js');
    require('./modules/animations/singleProfessionalsProject.js');
    require('./modules/animations/pdpHero.js');
    require('./modules/viewList.js');
    
    require('./modules/wishlist.js');
    require('./modules/miniCart.js');
    require('./modules/miniAccount.js');
    require('./modules/countrySelector.js');
    require('./modules/openSearch.js');
    require('./modules/designer.js');
    require('./modules/createAccount.js');
    require('./modules/order_return.js');
    require('./modules/news.js');
    require('./modules/customer-size-guide.js');
    require('./modules/accordion_customer.js');
    require('./modules/press-sort.js');
    require('./modules/open_newsletter_footer.js');
    require('./modules/press.js');
    
    require('./modules/menu_hover_image/index.js');

    // Splitting();
});
import * as focusTrap from 'focus-trap';

var focustrap_myaccount_inner = focusTrap.createFocusTrap('.my-account.inner', {returnFocusOnDeactivate: false});
 $('.logged-my-account').on('click focus', function (e) {
    $("body").addClass("overflow-hidden");
    e.preventDefault()
    $('.my-account.inner').show().attr('aria-hidden','false');
	focustrap_myaccount_inner.activate();

});


$('.close-my-accunt--inner').on('click', function (e) {
    $("body").removeClass("overflow-hidden");
    e.preventDefault()
    $('.my-account.inner').hide().attr('aria-hidden','true');
	focustrap_myaccount_inner.deactivate();
});
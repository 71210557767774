$('.amwishlist-list .amwishlist-button ').each(function () {
    $(this).on('click', function () {
        $(this).addClass('active').parents('.amwishlist-title').addClass('active').find('.amwishlist-select-block').attr('aria-hidden', 'false')
        console.log('fix fix')
    });
});







$('.amwishlist-item .share-your-wishlist ').each(function () {

    $(this).on('click', function (e) {
        e.preventDefault();

        $(this).parents('.edit-your-wishlist').hide().parents('.amwishlist-select-block').find('.share-your-wishlist-actions').show();
    });
});



$('.amwishlist-item .share-your-wishlist-actions .close-share-actions  button').each(function () {
    $(this).on('click', function () {
        console.log('close share')
        $(this).parents('.amwishlist-select-block').attr('aria-hidden', 'true');
        $(this).parents('.amwishlist-select-block').find('.share-your-wishlist-actions').hide().parents('.amwishlist-item ').find('.edit-your-wishlist').show();
    });
});


$('.amwishlist-item .edit-your-wishlist .close-share-actions button').each(function () {

    $(this).on('click', function () {
        console.log('close edit')
        $(this).parents('.amwishlist-title ').removeClass('active').find('.amwishlist-select-block').attr('aria-hidden', 'true')
    });
});



$('.amwishlist-title .share-trigger').each(function () {

    $(this).on('click', function () {
        console.log('click sharetrigger')
        $(this).parents('.amwishlist-title').find('.edit-your-wishlist').hide().parents('.amwishlist-title').find('.share-your-wishlist-actions').show();
        $(this).parents('.amwishlist-title ').find('.amwishlist-select-block').attr('aria-hidden', 'false')
    });
});



$('.amwishlist-preview-block ').each(function () {
    if ($(this).find('img').length == 1) {
        $(this).addClass('one-img')

    } else {
        console.log('2')

    }

});




$('.amwishlist-items .amwishlist-button.-more  ').each(function () {
    $(this).on('click', function () {
        $(this).addClass('active').parents('.amwishlist-items').addClass('active').find('.amwishlist-select-block').attr('aria-hidden', 'false')
        console.log('fix fix')
    });
});



$('.amwishlist-items__product .amwishlist-select-block .close-popup ').each(function () {
    $(this).on('click', function () {
        $(this).addClass('active').parents('.amwishlist-items').removeClass('active').find('.amwishlist-select-block').attr('aria-hidden', 'true')
        console.log('fix fix')
        $(this).parents('.amwishlist-items').find('.general-product_listing').show();
        $(this).parents('.amwishlist-items').find('.share-your-wishlist-actions').hide();

    });
});



$('.amwishlist-items__product .amwishlist-select-block .share-wishlist-trigger ').each(function () {

    $(this).on('click', function (e) {
        e.preventDefault();
        $(this).addClass('active').parents('.amwishlist-items').find('.general-product_listing').hide().parents('.amwishlist-item ').find('.share-your-wishlist-actions').show();

        console.log('fix fix')
    });
});



$('.amwishlist-items__product ').each(function () {

    const productName = $(this).find('.bottom_field .amwishlist-name').html();
    const functionName = $(this).find('.bottom_field span').html();

    // const productName2 = $(this).find('.bottom_field a');
    console.log('productName', productName, 'funzione', functionName)

    $(this).find('.amwishlist-item p').html(functionName)
    $(this).find('.amwishlist-item h2').html(productName)
});




var maxTime = 5000, // 5 seconds
        startTime = Date.now();

var interval = setInterval(function () {
    if ($('.wish-bread').is(':visible')) {


        $('#wishlist-view-form .amwishlist-item ').each(function () {
            const productLinks = $(this).find('.amwishlist-image-block').attr('href');
            // console.log('productlink', productLinks)

            $(this).find('.twitter-share-button').attr('href', "https://twitter.com/share?url=" + productLinks)
            $(this).find('.facebook-share-button').attr('href', "https://www.facebook.com/sharer/sharer.php?u=" + productLinks)
            $(this).find('.linkedin-share-button').attr('href', "https://www.linkedin.com/shareArticle?mini=true&url=" + productLinks)
        });


        $('#wishlist-view-form .more__trigger ').each(function () {
            $(this).on('click', function (e) {
                e.preventDefault();
                $('.open--list').show();
            });
        });


        $('#wishlist-view-form .share--your-wishlist ').each(function () {
            $(this).on('click', function (e) {
                e.preventDefault();
                $('.share-your-wishlist-actions__listing').show();
            });
        });



        $('#wishlist-view-form .close ').each(function () {
            $(this).on('click', function (e) {
                e.preventDefault();
                $('.open--list').hide();
                $('.share-your-wishlist-actions__listing').hide();
            });
        });



        $('#wishlist-view-form .share-list__trigger ').each(function () {
            $(this).on('click', function (e) {
                e.preventDefault();
                $('.share-your-wishlist-actions__listing').show();
                $('.open--list').show();
            });
        });


        $('.amwishlist-item .trigger__add-to-cart').each(function () {
            $(this).on('click', function (e) {
                e.preventDefault()
                $(this).parents('.amwishlist-item').find('.amwishlist-button.tocart').click()

            });
        });



        clearInterval(interval);
    } else {
        // still hidden
        if (Date.now() - startTime > maxTime) {
            // hidden even after 'maxTime'. stop checking.
            clearInterval(interval);
        }
    }
},
        100 // 0.1 second (wait time between checks)
        );



$('.add_to_wishlist_notlogger').on('click', function (e) {
    e.preventDefault()

    $('.customer-login-link').click()
});


$('.open--list .amwishlist-toolbar .deleteble').on('click', function () {


    $('.open--list').hide()
});



$('.edit-your-wishlist .-error a').on('click', function () {
    $(this).parents('.amwishlist-item').find('.amwishlist-title').removeClass('active')
    $(this).parents('.amwishlist-item').find('.amwishlist-select-block').attr('aria-hidden', 'true')
});





var maxTimeS = 5000, // 5 seconds
        startTimeT = Date.now();

var intervalNe = setInterval(function () {
    if ($('.shared_custom_url').is(':visible')) {
        const productLinks = $('.shared_custom_url').html();
        // console.log('productlink', productLinks)

        $('.open--list .twitter-share-button').attr('href', "https://twitter.com/share?url=" + productLinks)
        $('.open--list .facebook-share-button').attr('href', "https://www.facebook.com/sharer/sharer.php?u=" + productLinks)
        $('.open--list .linkedin-share-button').attr('href', "https://www.linkedin.com/shareArticle?mini=true&url=" + productLinks)


        clearInterval(intervalNe);
    } else {
        // still hidden
        if (Date.now() - startTimeT > maxTimeS) {
            // hidden even after 'maxTime'. stop checking.
            clearInterval(intervalNe);
        }
    }
},
        100 // 0.1 second (wait time between checks)
        );


$('body').on('click', '.clone-link', function () {
    var copyText = $(this).parents('.share-link').find('a')[0];
    navigator.clipboard.writeText(copyText.href);
    console.log(copyText.href)
});
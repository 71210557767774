$(".two_cols__link_and_image").each(function() {

  const image = $(this).find(".full-cover");
  const text = $(this).find(".ct-text .subtitle");
  const link = $(this).find(".ct-link .link-arrow");
  const linkText = $(this).find(".ct-link .text-1");
  const line = $(this).find(".ct-text .join-line");

  var tl = gsap.timeline({
    scrollTrigger: {
      trigger: $(this),
 
      scrub: 1.5,
      start: "top center",
      end: "bottom center",
      // markers: {startColor: "blue", endColor: "yellow", fontSize: "32px"},
      ease: "ease:ExpoScaleEase.config(0.5, 3, Power2.easeIn)",
    //   scrub: true,
      toggleActions: "play none none reverse"
    }
  });
  tl.from(text, {
    y: 400,

    duration: 1,
    stagger: 0.2
  })
  .from(image, {
    y: 200,
    opacity: 0,
    duration: 0.5
}, "-=0.5")

  .from(link, {
    y: 200,

    duration: 1,
    stagger: 0.2
  }, "-=0.5")
  .from(linkText, {
    y: 400,

    duration: 1,
    stagger: 0.2
  }, "-=0.5")
  .from(line, {
    y: 2300,

    duration: 0.5,
    stagger: 0.2
  }, "-=0.5")

  

  .to(text, {
    y: -50,

    duration: 1,
    stagger: 0.2
}, "+=1.5")
.to(link, {
    y: -500,
    opacity:0,
    duration: 2,
    stagger: 0.2
}, "-=.4")
  .to(linkText, {
    y: -350,
opacity:0,
    duration: 2,
    stagger: 0.2
}, "-=1")
  .to(image, {
    transform:'scale(1.2)',
  
    duration: 2.5
}, "-=2")

 
});

// const projectTriggers = document.querySelectorAll(".two_cols__link_and_image ");

// projectTriggers.forEach(addTimeline);

// function addTimeline(project, index) {
 
//   if ($(window).width() < 1025) {
 
//   }
//   else {
//   const image = project.querySelector(".full-cover");
//   const text = project.querySelector(".ct-text .subtitle");
//   const link = project.querySelector(".ct-link .link-arrow");
//   const linkText = project.querySelector(".ct-link .text-1");
//   const line = project.querySelector(".ct-text .join-line");
  

// //   ScrollTrigger.create({
// //     trigger: project,
// //     start: "top 75%",
// //     end: "bottom 25%",
// //     start: "top center",
 
// //     end: "bottom 80%",
// //     ease: "power2",
// //     markers: true,
// //     onEnter: () => timeline.play(),
// //     // onLeave: () => timeline2.duration(2).play(),
// //     // onEnterBack: () => action.duration(0.5).play(0),
// //     // onLeaveBack: () => action.pause(0)
// //   });

//   const timeline = gsap.timeline({
//     scrollTrigger: {
//       trigger: projectTriggers,
//       scrub: 1.5,
//       start: "top center",
//       end: "bottom center",
//       markers: {startColor: "blue", endColor: "yellow", fontSize: "32px"},
//       ease: "ease:ExpoScaleEase.config(0.5, 3, Power2.easeIn)",
//     //   scrub: true,
//       toggleActions: "play none none reverse"
//     }
//   })
//   .from(text, {
//     y: 400,

//     duration: 1,
//     stagger: 0.2
//   })
//   .from(image, {
//     y: 200,
//     opacity: 0,
//     duration: 0.5
// }, "-=0.5")

//   .from(link, {
//     y: 200,

//     duration: 1,
//     stagger: 0.2
//   }, "-=0.5")
//   .from(linkText, {
//     y: 400,

//     duration: 1,
//     stagger: 0.2
//   }, "-=0.5")
//   .from(line, {
//     y: 2300,

//     duration: 0.5,
//     stagger: 0.2
//   }, "-=0.5")

  

//   .to(text, {
//     y: -50,

//     duration: 1,
//     stagger: 0.2
// }, "+=1.5")
// .to(link, {
//     y: -500,
//     opacity:0,
//     duration: 2,
//     stagger: 0.2
// }, "-=.4")
//   .to(linkText, {
//     y: -350,
// opacity:0,
//     duration: 2,
//     stagger: 0.2
// }, "-=1")
//   .to(image, {
//     transform:'scale(1.2)',
  
//     duration: 2.5
// }, "-=2")
// }
 
// }
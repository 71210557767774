$(".select-country,.select-language").on('click', function () {
    var is_open = $(this).hasClass("open");
    if (is_open) {
        $(this).removeClass("open");
    } else {
        $(this).addClass("open");
    }
});


$(".select-country li").on('click', function () {
    var countrySelected = $(this).data('store');
    if (countrySelected != '') {
        $(".select-country li").removeClass('selected');
        $(".select-country li:first-child").before($(this));
        $(".select-language li").hide();
        $(".select-language li[data-store='" + countrySelected + "']").show();
    }
});

$(document).mouseup(function (event) {
    var target = event.target;
    var select = $(".select-country,.select-language");

    if (!select.is(target) && select.has(target).length === 0) {
        select.removeClass("open");
    }
});



$(document).ajaxComplete(function (event, xhr, settings) {
    //if (settings.url === "ajax/test.html") {}
    if ($('.popup-filters').length > 0) {
        setTimeout(function () {
            //// RIMUOVE ACTIVE VOCI MENU E NASCONDE TUTTI I CONTENUTI ////
            $('.popup-filters .filter-options-item').removeClass('active');
            $('.popup-filters .filter-options-item .filter-options-content').attr('aria-hidden', 'true').hide();
            console.log('tolgo active da voci popup filter')

            //// SPOSTA QUESTI FILTRI DENTRO FILTRI DIMENSIONE ////
            var $profondita = $('.popup-filters .filter-options-item.profondita');
            var $larghezza = $('.popup-filters .filter-options-item.larghezza');
            var $altezza = $('.popup-filters .filter-options-item.altezza');
            console.log($('.popup-filters .filter-options-item.dimensioni .filter-options-content .filter-options-item.altezza').length)
            if ($('.popup-filters .filter-options-item.dimensioni .filter-options-content .filter-options-item.altezza').length == 0) {
                $('.popup-filters .filter-options-item.dimensioni .filter-options-content').append($profondita).append($larghezza).append($altezza);
                console.log('entra sotto')
            }
        }, 400);
    }
});

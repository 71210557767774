
//// GEOLOCATION ////

if ($('.mpstorelocator-storelocator-store').length > 0) {

    function success_geolocation(position) {
        $('input#bh-sl-address').val(position.coords.latitude + ' ' + position.coords.longitude);
        $('button#bh-sl-submit').trigger('click');
    }

    function error_geolocation() {
        //alert("Permesso negato");
    }

    if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(success_geolocation, error_geolocation);
    } else {
        alert("Geolocation is not supported by this browser");
    }

    $('#bh-sl-address').on('click', function () {
        if (!$(this).val().length == 0) {
            $('.mpstorelocator-storelocator-store .bh-sl-container .form-input').addClass('digit');
        }else{
            $('.mpstorelocator-storelocator-store .bh-sl-container .form-input').removeClass('digit');
        }
    });

    $('#bh-sl-address').keydown(function () {
        $('.mpstorelocator-storelocator-store .bh-sl-container .form-input').addClass('digit');
    });

    $('#bh-sl-address').keyup(function () {
        if ($(this).val().length == 0) {
            $('.mpstorelocator-storelocator-store .bh-sl-container .form-input').removeClass('digit');
        }
    });

    $(document).on('click', function () {
       if (!$('.mpstorelocator-storelocator-store .pac-container').is(":visible")) {
            $('.mpstorelocator-storelocator-store .bh-sl-container .form-input').removeClass('digit');
        }
    });
}

//// EVENTI FILTRI CATALOGO ////

if ($('.catalogue--filters').length > 0) {

    var filters_selected_cat = '';
    $('.catalogue--filters').on('click', '.filter-button', function () {
        var $this = $(this);
        var filter = $this.data('filter');
        
        if (!$this.hasClass('active')) {
            $('.catalogue--filters .filter-button').removeClass('active');
            $this.addClass('active');
                
            if (filter != '') {
                filters_selected_cat = filter;
                $('.catalogue-item').hide();
                $('.catalogue-item').filter(filters_selected_cat).show();
            } else {
                filters_selected_cat = '';
                $('.catalogue-item').show();
            }
        }

        console.log(filters_selected_cat)
    });
}

if ($('.magento_rma-returns-create').length > 0) {

    var int = setInterval(function () {
        if ($('#registrant-options .field.item select').length > 0) {
            $('#registrant-options .field.item').each(function (i, el) {
                var img_selected = $(el).find('select option:selected').data('img-returns');
                var $img_returns = $('img[data-img-returns="' + img_selected + '"');
                $(el).parents('fieldset').find('.img_return').append($img_returns);
                $img_returns.show();
            });
            clearInterval(int);
        }
    }, 500);


    $('body').on('change', '#registrant-options .field.item select', function () {
        var img_returns = $(this).data('img-returns');
        var $img_returns = $('img[data-img-returns="' + img_returns + '"');
        $(this).parents('fieldset').find('.img_return').append($img_returns);
        $img_returns.show();
        console.log(img_returns)
        console.log($img_returns)
    });
}
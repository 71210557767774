import {preloader} from './preloader';
import Menu from './menu';

if ($('.inner--stories.menu').length > 0) {
    $('.inner--stories.menu').each(function (i, el) {
        preloader('.menu__item').then(() => {
            // initialize menu
            new Menu($(el)[0]);
        });
    });
}
import Swiper from 'swiper/bundle';
import * as focusTrap from 'focus-trap';

if ($('.swiper__prof__two_cols_left').length > 0) {

    //// INIZIALIZZA SLIDERS swiper__prof__two_cols_left ////
    var swiper_left = [];
    var swiper_right = [];
    $('.swiper__prof__two_cols_left').each(function (i, el) {
        $(el).parents('.prof__single_slide_product').data('index', i);
        var $prev = $(el).parents('.prof__single_slide_product').find('.swiper-button-prev')[0];
        var $next = $(el).parents('.prof__single_slide_product').find('.swiper-button-next')[0];

        swiper_left[i] = new Swiper($(el)[0], {
            slidesPerView: 1.15,
            spaceBetween: 8,
            disableOnInteraction: true,
            allowTouchMove: true,
            navigation: {
                nextEl: $next,
                prevEl: $prev
            },
            breakpoints: {
                768: {
                    slidesPerView: 1,
                    spaceBetween: 0,
                    disableOnInteraction: true,
                    allowTouchMove: false
                }
            }
        });
    });


    //// INIZIALIZZA SLIDERS swiper__prof__two_cols_right ////
    $('.swiper__prof__two_cols_right').each(function (i, el) {
        swiper_right[i] = new Swiper($(el)[0], {
            slidesPerView: 1,
            spaceBetween: 0,
            disableOnInteraction: true,
            allowTouchMove: false
        });
    });


    $(window).resize(function () {
        set_slider_height();
    });

    set_slider_height();

    function set_slider_height() {
        if ($(window).width() > 768) {

            $('.prof__single_slide_product').each(function (i, el) {
                var $ct_text = $(el).find('.ct-text');
                var w_slider = $(el).find('.swiper__prof__two_cols_left').width();
                $(el).find('.swiper__prof__two_cols_left .swiper-slide ').height(w_slider);
                var top_slide = $(el).find('.swiper__prof__two_cols_left').position().top;
                $ct_text.css('top', top_slide - 20);
            });

        } else {
            $('.swiper__prof__two_cols_left .swiper-slide ').height('auto');
        }
    }

    //evento frecce
    var time_transition = 300;
    $(".prof__single_slide_product .swiper-button-prev").bind("click", function () {
        var index = $(this).parents('.prof__single_slide_product').data('index');
        swiper_left[index].slidePrev(time_transition);
        swiper_right[index].slidePrev(time_transition);
    });

    $(".prof__single_slide_product .swiper-button-next").bind("click", function () {
        var index = $(this).parents('.prof__single_slide_product').data('index');
        swiper_left[index].slideNext(time_transition);
        swiper_right[index].slideNext(time_transition);
    });
}


//// INIZIALIZZA SLIDERS swiper__prof__three_cols ////
if ($('.swiper__prof__three_cols').length > 0) {

    $('.swiper__prof__three_cols').each(function (i, el) {
        var $prev = $(el).parents('.prof__single_slide_three_cols').find('.swiper-button-prev')[0];
        var $next = $(el).parents('.prof__single_slide_three_cols').find('.swiper-button-next')[0];

        new Swiper($(el)[0], {
            slidesPerView: 1.15,
            spaceBetween: 8,
            disableOnInteraction: true,
            navigation: {
                nextEl: $next,
                prevEl: $prev
            },
            breakpoints: {
                768: {
                    slidesPerView: 2.9,
                    spaceBetween: 26,
                    disableOnInteraction: true
                }
            }
        });
    });
}



//// EVENTI APERTURA CHIUSURA POPUP FILTRI PROGETTI ////

var _HEADER_WHITE = false;
if ($('header#navigation').hasClass('bg--white')) {
    _HEADER_WHITE = true;
}

var focustrap_filters_projects = focusTrap.createFocusTrap('.popup-filters-projects', {returnFocusOnDeactivate: false});
$('.view-filters-projects').on('click', function () {
    $("body").addClass("overflow-hidden");

    if ($(window).width() > 768) {
        $('.popup-filters-projects .close-filters').appendTo('.popup-filters-projects .sidebar.sidebar-main').show();
    } else {
        $('.popup-filters-projects .close-filters').appendTo('.popup-filters-projects .ct-actions .inner-actions').show();
    }
    //

    $('.popup-filters-projects').fadeIn('fast');
    focustrap_filters_projects.activate();

    if (!_HEADER_WHITE) {
        $('header#navigation').addClass('bg--white');
    }
});

$('.popup-filters-projects .close-filters, .popup-filters-projects .view-results').on('click', function () {
    $("body").removeClass("overflow-hidden");
    $('.popup-filters-projects').fadeOut('fast');
    focustrap_filters_projects.deactivate();

    if (!_HEADER_WHITE) {
        $('header#navigation').removeClass('bg--white');
    }
});



//// ISOTOPE ////

//var grid_projects = $('.row-projects').isotope({
//    layoutMode: 'fitRows',
//    itemSelector: '.grid-item',
//    masonry: {
//        horizontalOrder: true
//    }
//});



//// EVENTI FILTRI ////

if ($('.project-item').length > 0) {

    var projects_length = $('.project-item').length;
    $('.count-total-results').text(projects_length);

    $('.popup-filters-projects .filter-options-title').on('click', function () {
        var $this = $(this);
        $('.popup-filters-projects .filter-options-title').removeClass('active');
        $('.popup-filters-projects .filter-options-content').removeClass('active');
        $this.addClass('active');
        $this.siblings('.filter-options-content').addClass('active');
    });

    var filters_selected = [];
    var h_item = 48;
    $('.popup-filters-projects').on('click', '.filter-button', function () {
        var $this = $(this);
        var filter = $this.data('filter');

        if (!$this.hasClass('active')) {
            $this.addClass('active');
            filters_selected.push(filter);
            $('.popup-filters-projects .filter-actions, .ct-filters-selected .filter-actions').show();
            $('.popup-filters-projects .count-selected-filters').text(filters_selected.length);

            $('.popup-filters-projects .filter-current .items, .selected-filters .filter-current .items').append('<li class="item">'
                    + '<span class="filter-value">' + $this.text() + '</span>'
                    + '<button class="action remove remove-filter-projects" data-filter="' + filter + '" title="Remove ' + $this.text() + '"><span>Remove This Item</span></button>'
                    + '</li>');
        }

        $('.project-item').hide();
        $('.project-item').filter(filters_selected.join('')).show();     // Filter elements: class1 AND class2
        //$('.project-item').filter(filters_selected.join()).show();     //Filter elements: class1 OR class2
        $('.count-total-results').text($('.project-item:visible').length);

        console.log(filters_selected)
    });

    $('.selected-filters').on('click', '.remove-filter-projects', function () {
        var $this = $(this);
        var filter = $this.data('filter');
        $('.popup-filters-projects .filter-button[data-filter="' + filter + '"]').removeClass('active');
        $('.filter-current .item').each(function (i, el) {
            if (filter == $(el).find('.remove-filter-projects').data('filter')) {
                $(el).remove();
            }
        });
        filters_selected = filters_selected.filter(function (item) {
            return item !== filter
        });

        if (!filters_selected.length == 0) {
            $('.project-item').hide();
            $('.project-item').filter(filters_selected.join('')).show();     // Filter elements: class1 AND class2
            //$('.project-item').filter(filters_selected.join()).show();     //Filter elements: class1 OR class2
            $('.count-total-results').text($('.project-item:visible').length);
        } else {
            $('.project-item').show();
            $('.count-total-results').text(projects_length);
        }

        $('.popup-filters-projects .count-selected-filters').text(filters_selected.length);
        if (filters_selected.length == 0) {
            $('.popup-filters-projects .filter-actions, .ct-filters-selected .filter-actions').hide();
        }

        console.log(filters_selected)
    });

    $('.clear-filter-projects').on('click', function () {
        filters_selected = [];
        $('.popup-filters-projects .filter-button').removeClass('active');
        $('.popup-filters-projects .filter-actions, .ct-filters-selected .filter-actions').hide();
        $('.popup-filters-projects .count-selected-filters').text(0);
        $('.popup-filters-projects .selected-filters .items, .ct-filters-selected .selected-filters .items').html('');
        $('.project-item').show();
        $('.count-total-results').text(projects_length);
        console.log(filters_selected)
    });



    //// ACTIVE DEFAULT PRIME VOCI POPUP ////

    if ($('.popup-filters-projects').length > 0) {
        $('.popup-filters-projects .filter-options .filter-options-item:first-child .filter-options-title').addClass('active');
        $('.popup-filters-projects .filter-options .filter-options-item:first-child .filter-options-content').addClass('active');
    }



    //// EVENTO CARICA ALTRI PROGETTI ////

    var view_projects = 4;
    $('.load-more-project').on('click', function () {
        var visible_items = [];
        view_projects += 4;

        $('.project-item:nth-child(-n+' + view_projects + ')').css('display', 'flex');

        $('.project-item').each(function (i, el) {
            visible_items[i] = $(el).is(':visible');
        });

        console.log(visible_items)


        if (check_all_true(visible_items)) {
            $(this).hide();
        }
    });

    function check_all_true(array) {
        return array.every(function (element) {
            return element === true;
        });
    }
}
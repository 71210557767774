import * as focusTrap from 'focus-trap';

//var focustrap_search_box = focusTrap.createFocusTrap('.block-search', {returnFocusOnDeactivate: false});
 $('.icon-search a').on('click focus', function (e) {
    console.log('open search');
    e.preventDefault()
    $('.block-search').show().attr('aria-hidden','false');
//	focustrap_search_box.activate();
   $('.site-branding').addClass('black_logo')
   $("body").addClass("overflow-hidden");
});


$('.close-search').on('click focus', function (e) {
    console.log('close search');
    e.preventDefault()
    $('.block-search').hide().attr('aria-hidden','true');
   $('.site-branding').removeClass('black_logo')
   $("body").removeClass("overflow-hidden");
//   focustrap_search_box.deactivate();
});


if ($(".catalogsearch-result-index ")[0]){
    // Do something if class exists
    setTimeout(function(){  
        var countResults = $('.number-search-count').html()
    $('.count-result-for').html(countResults) }, 1000);

  
} else {
    // Do something if class does not exist
}

// $('.close-my-accunt--inner').on('click', function (e) {
//     e.preventDefault()
//     $('.my-account.inner').hide().attr('aria-hidden','true');
// 	focustrap_myaccount_inner.deactivate();
// });
 

  if ($(window).width() < 1025) {

  } else {

    //   const image = projectHero.querySelector(".full-cover");
    //   const text = projectHero.querySelector(".ct-text .subtitle");
    //   const link = projectHero.querySelector(".ct-link .link-arrow");
    //   const linkText = projectHero.querySelector(".ct-link .text-1");
    //   const line = projectHero.querySelector(".ct-text .join-line");
    var tl = new TimelineMax() 

    tl 

    .set('.ct-text-1,  .swatch-option, .pdp-tab-main, .breadcrumbs-pdp', {
      opacity: 1,
    })

    .set('.pdp__tab', {
      position:'fixed',
    })
    .from(funzione, {
      opacity: 0,
      y: 20,
      duration: 1,
      stagger: 0.2
    }, 1)

    .from(titolo, {
      opacity: 0,
      y: 20,
      duration: 1,
      stagger: 0.2
    }, 1)

    .to(image, {
      transform: 'scale(1.08)',
      duration: 1,
      stagger: 0.2
    }, 1)

    .from(breadcrumbs, {
      opacity: 0,
      y: 20,
      duration: 1,
      stagger: 0.2
    }, 2)

    .to('.list-colors', {
      opacity: 1,
      y: 0,
      duration: 1,
      stagger: 0.2
    }, 2)
    .from(tabs, {
      opacity: 0,
      y: 20,
      duration: 1,
      stagger: 0.2
    }, 2)

    //   ScrollTrigger.create({
    //     trigger: projectHero,
    //     start: "top 75%",
    //     end: "bottom 25%",
    //     start: "top center",

    //     end: "bottom 80%",
    //     ease: "power2",
    //     markers: true,
    //     onEnter: () => timeline.play(),
    //     // onLeave: () => timeline2.duration(2).play(),
    //     // onEnterBack: () => action.duration(0.5).play(0),
    //     // onLeaveBack: () => action.pause(0)
    //   });

    const image = document.querySelector(".pdp__first_block .ct-images");
    const funzione = document.querySelector(".pdp__first_block .subtitle");
    const titolo = document.querySelector(".pdp__first_block  .ct-text-1 .text-5");
    const swatcher = document.querySelectorAll(".pdp__first_block .swatch-option");
    const tabs = document.querySelectorAll(".pdp-tab-main li");
    const breadcrumbs = document.querySelectorAll(".breadcrumbs-pdp .items");
    const designer = document.querySelector(".pdp__first_block .designer-name");
    const descrizione = document.querySelector(".pdp__first_block .ct-text-2 .text-5");
    const cta = document.querySelector(".pdp__first_block .link-border");


    // scale img - comparsa terra e titolo
    // comparsa fade dal basso breadcrumbs - top navigation e colors
    // scroll terra e titolo  verso l'alto  a metà scale dell'img
    // continua lo scroll  e zoom img  e compare designer e descrizione e cta 
    // scroll designer e desc verso l'alto - zoom img



    const timelinePdpHero = gsap.timeline({
        scrollTrigger: {
        trigger: '.pdp__first_block',
         scrub: 1.4,
          pin: true,
          start: "50% 50%",
         end: "200%",
         refreshPriority: 1,
          ease: "ease:ExpoScaleEase.config(0.5, 3, Power2.easeIn)",
          //   scrub: true,
          toggleActions: "play none none reverse"
        }
      })

   

    
   
      .to(funzione, {
        y: '-100vh',
        duration: 1.3,
        stagger: 0.2
      }, 3)
    
      .to('.pdp__tab', {
        y: '-100vh',
        duration: 2.4,
        stagger: 0.2
      }, 3, '-=.3')

      .to(titolo, {
        y: '-100vh',
        duration: 2.4,
        stagger: 0.2
      }, 3, '-=.3')

      .to(image, {
        transform: 'scale(1.3)',
        duration: 5,
        stagger: 0.2
      }, 3, '-=1.5')
      .to('.ct-text-2', {
        opacity:1,
        duration: 3,
        visibility:'visible',
        stagger: 0.2
      }, 3, '-=1.5')
      .from('.list-colors', {
        opacity: 1,
        y: 20,
        duration: 1,
        stagger: 0.2
    }, 4)
      .from(designer, {
        opacity: 0,
        y: 20,
        duration: 1,
        stagger: 0.2
      }, 4)
      .from(descrizione, {
        opacity: 0,
        y: 20,
        duration: 1,
        stagger: 0.2
      }, 4)
      .from(cta, {
        opacity: 0,
        y: 20,
        duration: 1,
        stagger: 0.2
      }, 4)

      .to(designer, {
  
        y: '-100vh',
        duration: 1.2,
        stagger: 10.2
      }, 5,)
      .to(cta, {
        y: '-100vh',
        opacity:0,
        duration: 1.4,
        stagger: 10.2,
      }, 6,'+=5')
      .to(descrizione, {
        y: '-100vh',
        duration: 1.7,
        stagger: 10.2
      }, 5,'+=2')

    //   .to(pdpHero, {
    //     y:-600,
    //     duration: 1.2,
    //     stagger: 0.2
    //   }, 5, '-=.5')
      
 

    const singleImg = gsap.timeline({
        scrollTrigger: {
        trigger: '.single__gallery',
         scrub: 1.4,
    
          start: "top center",
end:'top center',
         refreshPriority: 1,
          ease: "ease:ExpoScaleEase.config(0.5, 3, Power2.easeIn)",
          //   scrub: true,

          // markers:true,
          toggleActions: "play none none none"
        }
      })
      .from('.single__gallery', {
   
        y: 420,
        opacity:0,
 
        duration: 1,
        stagger: 0.2
      }, 1)

}

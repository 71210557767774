import Swiper from 'swiper/bundle';

//// MOSTRA SLIDER PRODOTTO ////
if ($(window).width() > 768) {
    if ($('.grid__products').length > 0) {
        $('body').on('mouseenter', '.ct-version.has-slider .ct-version-image', function () {
            var $this = $(this);
            var $swiper__product = $this.find('.swiper__product');
            var $swiper__version = $this.find('.swiper__version');

            if ($swiper__product.length > 0) {

                if ($swiper__product.find('.swiper-slide').length > 1) {
                    //// INIZIALIZZA SLIDERS PRODOTTO ////
                    if (!$swiper__product.hasClass('swiper-container-initialized')) {
                        var $prev = $swiper__product.find('.swiper-button-prev')[0];
                        var $next = $swiper__product.find('.swiper-button-next')[0];
                        new Swiper($swiper__product[0], {
                            slidesPerView: 1,
                            spaceBetween: 0,
                            disableOnInteraction: true,
                            observer: true,
                            observeParents: true,
                            navigation: {
                                nextEl: $next,
                                prevEl: $prev
                            }
                        });
                    }
                }
            }

            if ($swiper__version.length > 0) {
                if ($swiper__version.find('.swiper-slide').length > 1) {
                    //// INIZIALIZZA SLIDERS VERSIONE ////
                    if (!$swiper__version.hasClass('swiper-container-initialized')) {
                        var $prev = $swiper__version.find('.swiper-button-prev')[0];
                        var $next = $swiper__version.find('.swiper-button-next')[0];
                        new Swiper($swiper__version[0], {
                            slidesPerView: 1,
                            spaceBetween: 0,
                            disableOnInteraction: true,
                            observer: true,
                            observeParents: true,
                            allowTouchMove: false,
                            navigation: {
                                nextEl: $next,
                                prevEl: $prev
                            }
                        });
                    }
                }
            }

            setTimeout(function () {
                if ($swiper__product.length > 0) {
                    if ($swiper__product.find('.swiper-slide').length > 1) {
                        $this.find('.swiper__product').fadeIn();
                        $this.find('img').hide();
                    }
                }
                if ($swiper__version.length > 0) {
                    if ($swiper__version.find('.swiper-slide').length > 1) {
                        $this.find('.swiper__version').fadeIn();
                        $this.find('img').hide();
                    }
                }
            }, 150);

        }).on('mouseleave', '.ct-version.has-slider .ct-version-image', function () {
            var $this = $(this);
            setTimeout(function () {
                $this.find('img').fadeIn();
                $this.find('.swiper__product').hide();
                $this.find('.swiper__version').hide();
            }, 150);
        });
    }
}

//// INIZIALIZZA SLIDERS PRODOTTO ////
//if ($('.swiper__product').length > 0) {
//    $('.swiper__product').each(function (i, el) {
//        var $prev = $(el).find('.swiper-button-prev')[0];
//        var $next = $(el).find('.swiper-button-next')[0];
//
//        new Swiper($(el)[0], {
//            slidesPerView: 1,
//            spaceBetween: 0,
//            disableOnInteraction: true,
//            observer: true,
//            observeParents: true,
//            navigation: {
//                nextEl: $next,
//                prevEl: $prev
//            }
//        });
//    });
//}

//// INIZIALIZZA SLIDERS VERSIONE ////
//if ($('.swiper__version').length > 0) {
//    $('.swiper__version').each(function (i, el) {
//        var $prev = $(el).find('.swiper-button-prev')[0];
//        var $next = $(el).find('.swiper-button-next')[0];
//
//        new Swiper($(el)[0], {
//            slidesPerView: 1,
//            spaceBetween: 0,
//            disableOnInteraction: true,
//            observer: true,
//            observeParents: true,
//            allowTouchMove: false,
//            navigation: {
//                nextEl: $next,
//                prevEl: $prev
//            }
//        });
//    });
//}




//// AGGIUNGE TASTO + SE CI SONO PIU DI 3 COLORI ////
$(document).on('swatch.initialized', function (e) {
    var $list_colors = $(e.target);
    console.log('swatch inizializzato, numero pallini:' + $list_colors.find('.swatch-option').length)

    setTimeout(function () {
        console.log($list_colors.find('.swatch-option').length)
        var count = 0;
        $list_colors.find('.swatch-option').each(function (i, el) {
            count++;
//            console.log(count)
        });
        if (count > 2) {
            $list_colors.prepend('<button class="expand-colors"></button>');
        }
    }, 1000);
});


if ($('.list-colors').length > 0) {

    //// EVENTO TASTO + SUI PALLINI COLORE ////
    $('main').on('click', '.expand-colors', function () {
        $(this).siblings('.swatch-attribute').find('.swatch-option').show();
        $(this).hide();
    });

    //// MOSTRA IMMAGINE E NASCONDE SLIDER AL CLICK DEI PALLINI COLORE SU MOBILE ////
    $('main').on('click', '.list-colors .swatch-option', function () {
        if ($(window).width() < 769) {
            var $this = $(this);
            $this.parents('.ct-version').find('.swiper__product').hide();
            $this.parents('.ct-version').find('.swiper__version').hide();
            $this.parents('.ct-version').find('.ct-version-image img').fadeIn();
        }
    });
}



//// GESTIONE BLOCCO INTRAMEZZO ////
$('main').on('click', '.choose-version', function (e) {
    e.preventDefault();
    var $this = $(this);
    var version = $this.data('version');
    var h_col = $this.parents('.columns').outerHeight(true);
    var top_col = $this.parents('.columns').position().top;
    var top_position = parseInt(h_col + top_col);
    var $other_version = $('.other-versions[data-version="' + version + '"]');
    $('.ct-other-version-js').append($other_version);
    $('.other-versions:not([data-version="' + version + '"])').slideUp();
    $other_version.css({'top': top_position}).slideDown(function () {
        var top_grid_products = $('.grid__products').offset().top;
        var top_position_scroll = (top_grid_products + top_position) - 200;
        $("html, body").animate({scrollTop: top_position_scroll}, 500);
    });

});

$('main').on('click', '.close-versions', function () {
    $(this).parents('.other-versions').slideUp();
});



//// FUNZIONI DA RICHIAMARE ANCHE AL LOAD MORE ////
  
var maxTimeLastView = 5000, // 5 seconds
startTimeLastView = Date.now();

var intervalLastView = setInterval(function() {
    if ($('.admin__data-grid-outer-wrap').is(':visible')) {

        $('.my-account--inner--latestviewed').prepend($('.admin__data-grid-outer-wrap'));

      clearInterval(intervalLastView);
    } else {
      // still hidden
      if (Date.now() - startTimeLastView > maxTimeLastView) {
        // hidden even after 'maxTime'. stop checking.
        clearInterval(intervalLastView);
      }
    }
  },
  100 // 0.1 second (wait time between checks)
);


import Swiper from 'swiper/bundle';

//// EVENTI CAMBIO TIPOLOGIA ////
if ($('.selector__tipology').length > 0) {

    //// INIZIALIZZA SLIDERS TIPOLOGIE ////
    var swiper__tipology = [];
    $('.swiper__tipology').each(function (i, el) {
        var $el = $(el);
        var tipology = $el.parents('.tipology-item').data('tipology');
        $el.addClass('swiper__tipology_' + tipology);

        swiper__tipology[i] = new Swiper($(el)[0], {
            slidesPerView: 1,
            spaceBetween: 0,
            //disableOnInteraction: true,
            observer: true,
            observeParents: true,
            allowTouchMove: false,
            loop: true,
            autoplay: {
                delay: 2000
            }
        });
    });

    $('.selector__tipology .list-tipology a').on('mouseenter', function () {
        change_tipology(this);
    });

    $('.selector__tipology .list-tipology a').on('focus', function () {
        change_tipology(this);
    });

    function change_tipology(self) {
        var $this = $(self);
        var tipology = $this.data('tipology');
        if (!$this.hasClass('active')) {
            $('.list-tipology a').removeClass('active');
            $this.addClass('active');
            $('.tipology-item').hide();
            $('.selector__ambient [data-content-type="slider"]').hide();    //solo per pagina ambiente
            $('.tipology-item').hide();
            var index = $('.tipology-item[data-tipology="' + tipology + '"]').index();
            $('.tipology-item[data-tipology="' + tipology + '"]').parents('[data-content-type="slider"]').show();    //solo per pagina ambiente
            $('.tipology-item[data-tipology="' + tipology + '"]').stop(true, true).fadeIn();

            $.each(swiper__tipology, function (i, el) {
                if (i != index) {
                    el.autoplay.stop();
                } else {
                    el.autoplay.start();
                    el.update();
                }
            });
        }
    }
}


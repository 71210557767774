const projectTriggersImg = document.querySelectorAll(".two_cols__image_link_and_image ");

projectTriggersImg.forEach(addTimeline);

function addTimeline(project, index) {

  if ($(window).width() < 1025) {
 
  }
  else {
  
  const bg = project.querySelector(".bg-beige");
  const image = project.querySelectorAll("img");
  const link = project.querySelector(".ct-text .link-arrow");
  const linkText = project.querySelector(".ct-text .text-1");
  // const line = project.querySelector(".ct-text .join-line");
  

//   ScrollTrigger.create({
//     trigger: project,
//     start: "top 75%",
//     end: "bottom 25%",
//     start: "top center",
 
//     end: "bottom 80%",
//     ease: "power2",
//     markers: true,
//     onEnter: () => timeline.play(),
//     // onLeave: () => timeline2.duration(2).play(),
//     // onEnterBack: () => action.duration(0.5).play(0),
//     // onLeaveBack: () => action.pause(0)
//   });

  const timeline = gsap.timeline({
    scrollTrigger: {
      trigger: project,
      // scrub: 1.1,
      start: "top center",
      // end: " +10%",
      ease: "ease:ExpoScaleEase.config(0.5, 3, Power2.easeIn)",
    //   scrub: true,
    // markers:true,
      toggleActions: "play none none reverse"
    }
  })
  .from(projectTriggersImg, {
    backgroundColor:'white',

    duration: 1,
    stagger: 0.2
  })
  .from(image, {
    y: 70,
    opacity:0,
    duration: 1,
    stagger: 0.01
  }, "+=.05")
  .from(link, {
    y: 50,
opacity:0,
    duration: 1.5,
    stagger: 0.2
  }, "-=1.5")
  .from(linkText, {
    y: 100,
    opacity:0,
    duration: 1.5,
    stagger: 0.2
  }, "-=1")
}
}
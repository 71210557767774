let toggles = document.getElementsByClassName("mobile-accordion");
let contentDiv = document.getElementsByClassName("footer-list");
let icons = document.getElementsByClassName("icon");

if ($(window).width() < 769) {
    for (let i = 0; i < toggles.length; i++) {
        toggles[i].addEventListener("click", () => {
            $('.mobile-accordion').removeClass("active");
            if (parseInt(contentDiv[i].style.height) != contentDiv[i].scrollHeight) {
                contentDiv[i].style.height = contentDiv[i].scrollHeight + 30 + "px";
                toggles[i].classList.add("active");
                //   icons[i].classList.remove("fa-plus");
                //   icons[i].classList.add("fa-minus");
            } else {
                contentDiv[i].style.height = "0px";
                toggles[i].classList.remove("active");
                //   icons[i].classList.remove("fa-minus");
                //   icons[i].classList.add("fa-plus");
            }

            for (let j = 0; j < contentDiv.length; j++) {
                if (j !== i) {
                    contentDiv[j].style.height = 0;
//              toggles[i].classList.remove("active");
                    // icons[j].classList.remove("fa-minus");
                    // icons[j].classList.add("fa-plus");
                }
            }
        });
    }
} else {

}


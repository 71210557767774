let projectTrigger = document.querySelectorAll('.prof__single_bg_text_and_link');

projectTrigger.forEach(project => {
    let timeline = gsap.timeline({
        scrollTrigger: {
            trigger: ".prof__single_bg_text_and_link",
            start: "center bottom",
            ease: "power2.easeOut",
            toggleActions: "play none none reverse",
        }
    })

    timeline
        .to(".prof__single_bg_text_and_link", {
            opacity: 1,
            stagger: 0.2
        }, "")
        .from(".prof__single_bg_text_and_link > .subtitle", {
            y: 50,
            opacity: 0,
            duration: 0.5
        }, "")
        .from(".prof__single_bg_text_and_link  .text-5", {
            y: 50,
            opacity: 0,
            duration: 0.5
        }).from(".prof__single_bg_text_and_link .subtitle-bottom", {
            y: 50,
            opacity: 0,
            duration: 0.5,
            stagger: 0.2
        }, "-=0.5")
    


})


let singleProjectInfo = document.querySelectorAll('.prof__single_info');

singleProjectInfo.forEach(project => {
    let timeline = gsap.timeline({
        scrollTrigger: {
            trigger: ".prof__single_info",
            start: "center bottom",
            ease: "power2.easeOut",
            toggleActions: "play none none reverse",
        }
    })

    timeline
      
       
        .from(".prof__single_info .list-info li", {
            y: 50,
            opacity: 0,
            duration: 0.5,
            stagger:.02
        }) 
          
        .from(".prof__single_info .text-info", {
            y: 50,
            opacity: 0,
            duration: 0.5,
            stagger:.02
        }) 
                 
        .from(".prof__single_info img", {
            y: 50,
            opacity: 0,
            duration: 0.5,
            stagger:.02
        }) 
    


})
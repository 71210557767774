
$('body').on('change', '#tipologia_professionista', function () {
    //var selectedCountry = $(this).children("option:selected").val();
    if ($(this).val() == '67') {
        $('.field-specializzazione').show();
    } else {
        $('.field-specializzazione').hide();
    }
});


$('body').on('click', '.field-gruppo_customer .select-items div', function () {
    $(".field-gruppo_customer .select-items div").removeClass('isSelected');
    $(this).addClass('isSelected');

    if ($(this).attr('data-value') == '62') {
        $('.field-tipologia_professionista').show();
    } else {
        $('.field-tipologia_professionista').hide();
        $('.field-specializzazione').hide();
    }
});

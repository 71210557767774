import * as focusTrap from 'focus-trap';

var focustrap_myaccount_inner = focusTrap.createFocusTrap('.mpblog-newsletter-popup-index', {returnFocusOnDeactivate: false});
 $('.blog_newsletter ').on('click focus', function (e) {
    $("body").addClass("overflow-hidden");
    e.preventDefault()
    $('.mpblog-newsletter-popup-index').show().attr('aria-hidden','false');
	focustrap_myaccount_inner.activate();

});


$('.mpblog-newsletter-popup-index .closeNews').on('click', function (e) {
    $("body").removeClass("overflow-hidden");
    e.preventDefault()
    $('.mpblog-newsletter-popup-index').hide().attr('aria-hidden','true');
	focustrap_myaccount_inner.deactivate();
});
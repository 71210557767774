$('.toolbar-products .grid').on('click', function () {
    $('.grid__products').addClass('display-grid')
    $('.triggerList').removeClass('active')
    $(this).addClass('active')
   });


   $('.toolbar-products .view-list').on('click', function () {
       $('.grid__products').removeClass('display-grid')
       $('.triggerList').removeClass('active')
       $(this).addClass('active')
   });
   
import Swiper from 'swiper/bundle';
import * as focusTrap from 'focus-trap';

//// INIZIALIZZA SLIDERS GALLERY ////
if ($('.swiper__gallery').length > 0) {

    $('.swiper__gallery').each(function (i, el) {
        var $prev = $(el).parents('.popup__gallery').find('.swiper-button-prev')[0];
        var $next = $(el).parents('.popup__gallery').find('.swiper-button-next')[0];
        var $pagination = $(el).parents('.popup__gallery').find('.swiper-pagination')[0];

        new Swiper($(el)[0], {
            slidesPerView: 1,
            spaceBetween: 0,
            disableOnInteraction: true,
            observer: true,
            observeParents: true,
            pagination: {
                el: $pagination,
                type: 'fraction',
            },
            navigation: {
                nextEl: $next,
                prevEl: $prev
            }
        });
    });
}



//// APRE GALLERY ////
var focustrap_gallery = [];
var focustrap_gallery_ref = [];
$('.popup__gallery').each(function(i, el) {
    focustrap_gallery[i] = focusTrap.createFocusTrap($(el)[0], {returnFocusOnDeactivate: false});
    focustrap_gallery_ref[i] = $(el).data('ref');
});

$('.view-gallery, .ct-point-gallery').on('click focus', function () {
    var ref = $(this).data('ref');
    $("body").addClass("overflow-hidden");
    $('header#navigation').hide();
    $('.popup__gallery[data-ref="' + ref + '"]').fadeIn();
    $.each(focustrap_gallery_ref, function (i, ref_popup) {
        if (ref == ref_popup) {
            focustrap_gallery[i].activate();
        }
    });
});

//// CHIUDE GALLERY ////
$('.popup__gallery .close-popup').on('click', function () {
    var ref = $(this).parents('.popup__gallery').data('ref');
    $("body").removeClass("overflow-hidden");
    $('header#navigation').show();
    $('.popup__gallery').fadeOut();
    $.each(focustrap_gallery_ref, function (i, ref_popup) {
        if (ref == ref_popup) {
            focustrap_gallery[i].deactivate();
        }
    });
});

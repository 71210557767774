import Swiper from 'swiper/bundle';

//// INIZIALIZZA SLIDERS VARIANTI ////
if ($('.swiper__variants').length > 0) {

    $('.swiper__variants').each(function (i, el) {

        if ($(el).parents('.tipology-item').find('.swiper-button-prev').length > 0) {
            //per multi slider
            var $parent = $(el).parents('.tipology-item');
            var $prev = $parent.find('.swiper-button-prev')[0];
            var $next = $parent.find('.swiper-button-next')[0];
            var $pagination = $parent.find('.swiper-pagination')[0];
        } else {
            //per slider singolo
            var $parent = $(el).parents('.concept__selector_variants');
            var $prev = $parent.find('.swiper-button-prev')[0];
            var $next = $parent.find('.swiper-button-next')[0];
            var $pagination = $parent.find('.swiper-pagination')[0];
        }

        new Swiper($(el)[0], {
            slidesPerView: 1.08,
            spaceBetween: 9,
            disableOnInteraction: true,
            observer: true,
            observeParents: true,
            pagination: {
                el: $pagination,
                type: "fraction",
            },
            navigation: {
                nextEl: $next,
                prevEl: $prev
            },
            breakpoints: {
                768: {
                    slidesPerView: 2.13,
                    spaceBetween: 24,
                    disableOnInteraction: true,
                    observer: true,
                    observeParents: true,
                }
            }
        });
    });
}

//// EVENTI CAMBIO VARIANTI ////
if ($('.concept__selector_variants').length > 0) {

    $('.concept__selector_variants .list-tipology').on('click', 'button', function () {
        change_variants(this);
    });

    $('.concept__selector_variants .list-tipology').on('focus', 'button', function () {
        change_variants(this);
    });

    function change_variants(self) {
        var $this = $(self);
        var tipology = $this.data('tipology');
        if (!$this.hasClass('active')) {
            $('.concept__selector_variants .list-tipology button').removeClass('active');
            $this.addClass('active');
            $('.concept__selector_variants .tipology-item').hide().removeClass('active');
            // gsap.to($(this).find('.swiper-slide '), {
            //     duration: 0.5, 
            //     opacity: 0, 
            //     y: 20, 
            //     stagger: 0.1,
            //     ease: "back.in"
            //   });
            $('.concept__selector_variants .tipology-item[data-tipology="' + tipology + '"]').stop(true, true).fadeIn(function () {
                $(this).addClass('active');
                // gsap.to($(this).find('.swiper-slide '), {
                //     duration: 0.5, 
                //     opacity: 1, 
                //     y: -20, 
                //     stagger: 0.1,
                //     ease: "back.in"
                //   });
            });
        }

        //funzione comune select trasformata mobile
        if ($(window).width() < 769) {
            var selected_value = $this.parents('li').html();
            var first_li = $this.parents('.list-to-select').find("li:first-child").html();
            $this.parents('.list-to-select').find("li:first-child").html(selected_value);
            $this.parents('li').html(first_li);
            setTimeout(function () {
                $(".list-to-select").removeClass("open");
            }, 150);
        }
    }
}


//if ($('.swiper__concept__two_cols_left').length > 0) {
//
//    //// INIZIALIZZA SLIDERS swiper__concept__two_cols_left ////
//    var $prev = $('.swiper__concept__two_cols_left .swiper-button-prev');
//    var $next = $('.swiper__concept__two_cols_left .swiper-button-next');
//
//    var swiper_left = new Swiper('.swiper__concept__two_cols_left', {
//        slidesPerView: 1,
//        spaceBetween: 0,
//        disableOnInteraction: true,
//        allowTouchMove: false,
//        navigation: {
//            nextEl: $next[0],
//            prevEl: $prev[0]
//        },
////            breakpoints: {
////                768: {
////                    slidesPerView: 2.13,
////                    spaceBetween: 24,
////                    disableOnInteraction: true,
////                    observer: true,
////                    observeParents: true,
////                }
////            }
//    });
//
//    //// INIZIALIZZA SLIDERS swiper__concept__two_cols_right ////
//    var swiper_right = new Swiper('.swiper__concept__two_cols_right', {
//        slidesPerView: 1,
//        spaceBetween: 0,
//        disableOnInteraction: true,
//        allowTouchMove: false,
////            breakpoints: {
////                768: {
////                    slidesPerView: 2.13,
////                    spaceBetween: 24,
////                    disableOnInteraction: true,
////                    observer: true,
////                    observeParents: true,
////                }
////            }
//    });
//
//
//    $(window).resize(function () {
//        set_slider_height();
//    });
//
//    set_slider_height();
//
//    function set_slider_height() {
//        if ($(window).width() > 768) {
//            var w_slider = $('.swiper__concept__two_cols_left').width();
//            $('.swiper__concept__two_cols_left').height(w_slider);
//        } else {
//            $('.swiper__concept__two_cols_left').height('auto');
//        }
//    }
//
//    //evento frecce
//    var time_transition = 300;
//    $(".swiper__concept__two_cols_left .swiper-button-prev").bind("click", function () {
//        swiper_left.slidePrev(time_transition);
//        swiper_right.slidePrev(time_transition);
//    });
//
//    $(".swiper__concept__two_cols_left .swiper-button-next").bind("click", function () {
//        swiper_left.slideNext(time_transition);
//        swiper_right.slideNext(time_transition);
//    });
//}


//// IMPOSTA ALTEZZA COLONNE DESIGNER DESKTOP ////
if ($('.concept__designer').length > 0) {

    set_slide_height();

    $(window).resize(function () {
        set_slide_height();
    });

    function set_slide_height() {
        $('.concept__designer .swiper__from_cols .swiper-slide').height('auto');
        var h_slider = $('.concept__designer .swiper__from_cols .swiper-wrapper').height();
        $('.concept__designer .swiper__from_cols .swiper-slide').height(h_slider);
    }
}


//// IMPOSTA ALTEZZA IMMAGINE DI SINISTRA PROJECTS DESKTOP ////
if ($('.concept__projects').length > 0) {

    set_image_left_height();

    $(window).resize(function () {
        set_image_left_height();
    });

    function set_image_left_height() {
        if ($(window).width() > 768) {
            var h_ct_text = $('.concept__projects .ct-col-left .ct-text').outerHeight(true);
            $('.concept__projects .ct-col-left .ct-image-text-lateral').height('calc(100% - ' + h_ct_text + 'px)');
        } else {
            $('.concept__projects .ct-col-left .ct-image-text-lateral').height('100%');
        }
    }
}


//// INIZIALIZZA SLIDERS swiper__concept__comunication ////
if ($('.swiper__concept__comunication').length > 0) {

    var slider_c_comunication = [];
    $('.swiper__concept__comunication').each(function (i, el) {
        var $prev = $(el).parents('.concept__project_comunication').find('.swiper-button-prev')[0];
        var $next = $(el).parents('.concept__project_comunication').find('.swiper-button-next')[0];

        slider_c_comunication[i] = new Swiper($(el)[0], {
            slidesPerView: 1.08,
            spaceBetween: 8,
            disableOnInteraction: true,
            navigation: {
                nextEl: $next,
                prevEl: $prev
            },
            breakpoints: {
                768: {
                    slidesPerView: 1,
                    spaceBetween: 0,
                    disableOnInteraction: true
                }
            }
        });
    });

    set_arrow_left();

    $(window).resize(function () {
        set_arrow_left();
    });

    function set_arrow_left() {
        var margin_left = 0;
        if ($(window).width() > 768) {
            if ($(window).width() >= 1024) {
                margin_left = 52;
            } else {
                margin_left = 32;
            }

            $('.concept__project_comunication').each(function (i, el) {
                $(el).find('.ct-arrow').css('left', $(el).find('.ct-col-right:eq(' + slider_c_comunication[i].activeIndex + ')').offset().left - margin_left).show();
            });

        } else {
            $('.ct-arrow').hide();
        }
    }
}
gsap.set(".concept__selector_variants .list-tipology  li", {opacity: 0, y: 24});

ScrollTrigger.batch(".concept__selector_variants .list-tipology  li", {
  onEnter: batch => gsap.to(batch, {opacity: 1, y: 0, stagger: 0.15}),
  // onLeave: batch => gsap.to(batch, {opacity: 0, y: 50}),
  // onEnterBack: batch => gsap.to(batch, {opacity: 1, y: 0, stagger: 0.15}),
  // onLeaveBack: batch => gsap.to(batch, {opacity: 0, y: 50}),

  start: "top 50%",
  end: "bottom 20%",
  scrub:true,
//   markers: true,
});


gsap.set(".concept__selector_variants .swiper-slide .ct-version  ", {opacity: 0, y: 24});

ScrollTrigger.batch(".concept__selector_variants .swiper-slide .ct-version", {
  onEnter: batch => gsap.to(batch, {opacity: 1, y: 0, stagger: 0.15}),
  // onLeave: batch => gsap.to(batch, {opacity: 0, y: 50}),
  // onEnterBack: batch => gsap.to(batch, {opacity: 1, y: 0, stagger: 0.15}),
  // onLeaveBack: batch => gsap.to(batch, {opacity: 0, y: 50}),

  start: "top 50%",
  end: "bottom 20%",
  scrub:true,
//   markers: true,
});
//// EVENTO CARICA ALTRI PROGETTI ////

var view_projects = 4;
$('.load-more-project').on('click', function () {
    var visible_items = [];
    view_projects += 4;

    $('.row-designer .columns:nth-child(-n+' + view_projects + ')').css('display', 'block');

    $('.row-designer .columns').each(function (i, el) {
        visible_items[i] = $(el).is(':visible');
    });

    console.log(visible_items)


    if (check_all_true(visible_items)) {
        $(this).hide();
    }
});

function check_all_true(array) {
    return array.every(function (element) {
        return element === true;
    });
}
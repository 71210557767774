
if ($('.checkout-cart-index').length > 0) {


    //// SPOSTA LINK WISHLIST ////
    $('.checkout-cart-index .cart.item').each(function (i, el) {
        if ($(window).width() > 768) {
            var $to_wishlist = $(el).find('.action-towishlist');
            $(el).find('.col.item .product-item-details').append($to_wishlist);
        }
    });

    //// IMPOSTA ALTEZZA CART SUMMARY ////
    set_summary_height();

    $(window).resize(function () {
        set_summary_height();
    });

    function set_summary_height() {
        if ($(window).width() > 768) {
            $('.cart-summary').height($('.cart-container').outerHeight() + 15);
        }
    }


}
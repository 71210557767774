import * as focusTrap from 'focus-trap';


//// EVENTI SELEZIONE FILTRO MOBILE ////
if ($('.nav-menu-products .list-menu').length > 0) {

    if ($(window).width() < 769) {
        $('.nav-menu-products .list-menu').on('click', 'a', function (e) {
            //e.preventDefault();
            var $this = $(this);
            $(".list-menu a").removeClass('active');
            $this.addClass('active');
            //funzione comune select trasformata mobile
            var selected_value = $this.parents('li').html();
            var first_li = $this.parents('.list-to-select').find("li:first-child").html();
            $this.parents('.list-to-select').find("li:first-child").html(selected_value);
            $this.parents('li').html(first_li);
            setTimeout(function () {
                $(".list-menu").removeClass("open");
            }, 150);
        });
    }
}


//// EVENTI APERTURA CHIUSURA POPUP FILTRI ////

var _HEADER_WHITE = false;
if ($('header#navigation').hasClass('bg--white')) {
    _HEADER_WHITE = true;
}

var focustrap_filters_popup = focusTrap.createFocusTrap('.popup-filters', {returnFocusOnDeactivate: false});
var count_append = 0;
$('.view-filters').on('click', function () {
    $("body").addClass("overflow-hidden");

    if (count_append == 0) {        //TODO - forse non serve piu
        //sposta questi elementi di magento all'interno degli elementi custom
        //$('.popup-filters .filter-current').appendTo('.selected-filters').show();
        //$('.popup-filters .filter-actions').appendTo('.ct-reset-filters').show();
        //$('.number-selected-filters').appendTo('');

        if ($(window).width() > 768) {
            $('.popup-filters .close-filters').appendTo('.popup-filters .sidebar.sidebar-main').show();
        } else {
            $('.popup-filters .close-filters').appendTo('.popup-filters .ct-actions .inner-actions').show();
        }
    }
    count_append++;
    //

    $('.popup-filters').fadeIn('fast');
    focustrap_filters_popup.activate();

    if (!_HEADER_WHITE) {
        $('header#navigation').addClass('bg--white');
    }
});


///// NUOVO - TEST ONLINE //////
//$('.filter-options-content').on('click', '.item a', function () {
//    $('.selected-filters').html('');
//    var t = setInterval(function () {
//        $('.block-content.filter-content > #am-shopby-container').appendTo('.selected-filters').show();
//        console.log('entra')
//        if ($('.selected-filters #am-shopby-container').length > 0) {
//            clearInterval(t);
//            console.log('entra 2')
//        }
//    }, 100);
//});



$('.popup-filters').on('click', '.close-filters, .view-results', function () {
    $("body").removeClass("overflow-hidden");
    var total_number = $('.columns-outer .toolbar-amount .toolbar-number:last-child').html();
    $('.count-products').html(total_number);
    $('.popup-filters').fadeOut('fast');
    focustrap_filters_popup.deactivate();

    if (!_HEADER_WHITE) {
        $('header#navigation').removeClass('bg--white');
    }
});


//// EVENTO APERTURA SELECT MOBILE (TODO - da rivedere poi con l'integrazione per gli eventi) ////
if ($('.filter-options').length > 0) {
    if ($(window).width() < 769) {

        var h_item = 48;
        var h_total = 0;
        $('.filter-options .filter-options-item').each(function (i, el) {
            h_total += h_item;
        });

        $(".filter-options").on('click', function (e) {
            //e.preventDefault();
            console.log($(e.target).closest('a').length)
            if (!$(e.target).hasClass('filter-button') && !$(e.target).closest('a').length) {
                var $this = $(this);
                var is_open = $this.hasClass("open");
                if (is_open) {
                    $this.removeClass("open");
                    $this.height(h_item + 'px');
                } else {
                    $this.addClass("open");
                    $('.filter-options .filter-options-content').hide();
                    $this.height(h_total + 'px');
                }
            }
        });

        $('.filter-options .filter-options-item').on('click', '.filter-options-title', function (e) {
            //e.preventDefault();
            var $this = $(this);
            //funzione comune select trasformata mobile
            var selected_value = $this.parents('.filter-options-item').html();
            var first_li = $(".filter-options .filter-options-item:first-child").html();
            $(".filter-options .filter-options-item:first-child").html(selected_value);
            $this.parents('.filter-options-item').html(first_li);
            $('.filter-options .filter-options-content').show();
            setTimeout(function () {
                $(".filter-options").removeClass("open");
            }, 150);
            
            
            /// TEST ///
//            $('.filter-options .filter-options-item').removeClass('active');
//            $this.parents('.filter-options-item').addClass('active');
            
            
            console.log('entra mobile')
        });
        
    } else {
        
        /// NUOVO - TESTARE ONLINE (non viene preso l'evento) ///
//        $('.filter-options .filter-options-item').on('click', '.filter-options-title', function (e) {
//            //e.preventDefault();
//            var $this = $(this);
//            $('.filter-options .filter-options-item').removeClass('active');
//            $this.parents('.filter-options-item').addClass('active');
//            $('.filter-options .filter-options-item .filter-options-content').hide();
//            $this.siblings('.filter-options-content').show();
//            console.log('fatto')
//        });
    }
}


export { focustrap_filters_popup };
gsap.set(".ct-image-text-lateral", {opacity: 0, y: 24});

ScrollTrigger.batch(".ct-image-text-lateral", {
  onEnter: batch => gsap.to(batch, {opacity: 1, y: 0, stagger: 0.15}),
  onLeave: batch => gsap.to(batch, {opacity: 0, y: 50}),
  onEnterBack: batch => gsap.to(batch, {opacity: 1, y: 0, stagger: 0.15}),
  onLeaveBack: batch => gsap.to(batch, {opacity: 0, y: 50}),

  start: "top 80%",
  end: "bottom 20%",
  scrub:true,
  markers:true,
//   markers: true,
});


const projectTriggers = document.querySelectorAll(".selector__tipology ");

projectTriggers.forEach(addTimeline);

function addTimeline(project, index) {

  if ($(window).width() < 1025) {
 
  }
  else {
  
  // const image = project.querySelector(".full-cover");
  const text = project.querySelectorAll(".list-tipology li");
  const link = project.querySelector(".full-height-vh.no-padding");
 
 
  

//   ScrollTrigger.create({
//     trigger: project,
//     start: "top 75%",
//     end: "bottom 25%",
//     start: "top center",
 
//     end: "bottom 80%",
//     ease: "power2",
//     markers: true,
//     onEnter: () => timeline.play(),
//     // onLeave: () => timeline2.duration(2).play(),
//     // onEnterBack: () => action.duration(0.5).play(0),
//     // onLeaveBack: () => action.pause(0)
//   });

  const timeline = gsap.timeline({
    scrollTrigger: {
      trigger: project,
 
      start: "top center",
 
      ease: "ease:ExpoScaleEase.config(0.5, 3, Power2.easeIn)",
    //   scrub: true,
      toggleActions: "play none none reverse"
    }
  })
 
 
  .from(text, {
    y: 40,
opacity:0,
    duration: 1,
    stagger: {
      amount: .2, 
 
    }
  })

  .from(link, {
 
    opacity:0,
    duration: 1,
 
  }, "-=0.5")
 
   
  }
 
}